const initialState = {
	available : [],
    selected: {},
	completed: false
};

export default (state = initialState, action) => {
	
    switch(action.type) {
    
        case 'SET_DOCUMENTLIST':
            return action.documents;
            
        default:
            return state;
    }
};