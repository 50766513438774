import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {removeUser} from "../../store/actions/User";
import {addDocument, createRegistration, getRegistration, runRegistration} from "../../store/actions/Registration";
import {setDocuments} from "../../store/actions/Documents";
import {sendRequest} from "../../helpers/Request";
import {removeFromQueue} from "../../store/actions/Queue";
import $ from "jquery";
import moment from "moment";

class HeaderDefault extends Component {
    _isMounted = false;
    dateInterval;
    queueInterval;
    resultInterval;

    constructor(props) {
        super(props);

        this.initialState = {
            showNav: false,
            resultCount: 1
        };

        this.state = this.initialState;
    };

    componentDidMount() {
        $('.progress').hide();
        $('.notice').hide();

        const notLoggedInPages = ['/', '/login', '/forgot-password', '/privacy'];

        if(this.props.user.username !== '' && notLoggedInPages.indexOf(this.props.location.pathname) === -1) {
            this.updateActiveDate();
            this.dateInterval = setInterval(() => this.updateActiveDate(), 1000);
        }

        this.handleQueue();
        this.queueInterval = setInterval(() => this.handleQueue(), 2000);

        if(this.props.registration !== undefined && Object.keys(this.props.registration).length > 0 && this.props.location.pathname === '/result') {
            this.props.dispatch(getRegistration(this.props.registration));
            this.pollRegistration();
            this.resultInterval = setInterval(() => this.pollRegistration(), 2000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.dateInterval);
        clearInterval(this.queueInterval);
        clearInterval(this.resultInterval);
    }

    updateActiveDate = () => {
        const lastActiveDate = localStorage.getItem('LAST-ACTIVE-DATE');
        const dateNow = moment().format('YYYY-MM-DD HH:mm:ss');

        if(lastActiveDate !== null) {
            const difference = moment.duration(moment(dateNow).diff(moment(lastActiveDate)));

            if (difference.asMinutes() >= 60) {
                this.props.history.push('/process');
            }
        }

        localStorage.setItem('LAST-ACTIVE-DATE', dateNow);
    };

    pollRegistration = () => {
    	let gotoError = false;
    	
        if(this.state.resultCount < 20) { 
        	if('registration' in this.props) {
        		if('documents' in this.props.registration) {        			
        			for(var i = 0, len = this.props.registration.documents.length; i < len; ++ i) {
        				let currentDocument = this.props.registration.documents[i];
        				
        				if(currentDocument.processor === 'expert'
        					&& currentDocument.processor_finished !== true) {
        					
        					gotoError = true;
        					break;
        				}
        			}
        		}
        	}
        	
        	if(gotoError === false) {
        		this.props.dispatch(getRegistration(this.props.registration));
        	}
        	
            this.setState({
                resultCount: (this.state.resultCount + 1)
            });
        } 
        else if(this.state.resultCount >= 20) {
        	gotoError = true;
        }
        
        if(gotoError === true) {
        	clearInterval(this.resultInterval);

            if(this.props.registration.acceptation.total_result !== 'ok' 
            	&& this.props.registration.acceptation.total_result !== 'manual') {
            	
                this.props.history.push('/result/error');
            }
        }
    };

    handleQueue = () => {
        const queue = this.props.queue;

        if(Object.keys(queue).length > 0) {
            Object.keys(queue).forEach((queueIdentifier) => {
                if(queue[queueIdentifier].length > 0) {

                    switch(queueIdentifier) {

                        case 'UPLOAD':
                            queue[queueIdentifier].forEach(async(queueItem, key) => {
                                await this.handleUploadItem(queueItem, key).then((responseObject) => {
                                    this.checkForDocumentRun(responseObject.upload).then((response) => {
                                        console.log(response);
                                    });
                                });
                            });
                            break;
                        default:
                            return queue;
                    }
                }
            });
        }
    };

    checkForDocumentRun = async (upload) => {
        if(upload.document.run === true) {
            return new Promise((resolve) => {
                this.runDocument(this.props.registration, this.props.documents.selected).then((response) => {
                    console.log(response);
                });
            });
        } else {
            return 'NOT-RUNNABLE';
        }
    };

    animateProgress = (animateConfig) => {
        let delayAmount = 0;

        if(animateConfig.delay !== undefined && animateConfig.delay > 0) {
            delayAmount = animateConfig.delay;
        }

        $(document).ready(function() {
            $('.progress--' + animateConfig.side).show();
            $('.notice--' + animateConfig.side).show();

            if(animateConfig.width !== undefined) {
                $('.progress--' + animateConfig.side + ' .progress__indicator span').animate({
                    width: animateConfig.width
                }, delayAmount, function() {

                    if(animateConfig.hideProgress === true) {
                        $('.progress--' + animateConfig.side).hide();
                    }

                    if(animateConfig.hideNotice === true) {
                        $('.notice--' + animateConfig.side).hide();
                    }
                });
            }
        });

        return new Promise((resolve) => {
            setTimeout(() => resolve('OK'), delayAmount);
        });
    };

    handleUploadItem = async (upload, queueKey) => {
        let registration = null;
        let document = null;

        if(upload.fileData.length > 0) {
            this.removeQueueItem('UPLOAD', queueKey);

            return new Promise(async(resolve) => {
                if(upload.createRegistration === true) {
                    registration = await this.props.dispatch(createRegistration(this.props.sets.selected));
                    registration = registration.registration;
                } else {
                    registration = this.props.registration;
                }

                await this.animateProgress({
                    width: '25%',
                    hideProgress: false,
                    hideNotice: false,
                    delay: 1000,
                    side: upload.side
                });

                if(Object.keys(registration).length > 0) {

                    if(upload.createDocument === true) {
                        document = await this.props.dispatch(addDocument(registration, upload));
                        document = document.document;
                    } else {
                        document = this.props.documents.selected;
                    }

                    await this.animateProgress({
                        width: '50%',
                        hideProgress: false,
                        hideNotice: false,
                        delay: 1000,
                        side: upload.side
                    });

                    if(Object.keys(document).length > 0) {
                        const documents = this.props.documents;

                        let available = [];

                        documents.available.forEach(function (item) {

                            if (document.document_column === item.document_column) {
                                if (document.type === 'passport' && item.document_column === 'id_document') {
                                    item.disabled = true;
                                    item.required = false;
                                }
                            }

                            if (document.type === item.document_type) {
                                item.document_id = document.document_id;
                            }

                            available = [...available, item];
                        });

                        documents.available = available;
                        documents[upload.side] = upload.fileData;

                        if(Object.keys(documents.selected).length <= 0) {
                            documents.selected = document;
                        }

                        await this.props.dispatch(setDocuments(documents));

                        if(Object.keys(documents).length > 0) {
                            await this.animateProgress({
                                width: '75%',
                                hideProgress: false,
                                hideNotice: false,
                                delay: 1000,
                                side: upload.side
                            });

                            const image = {
                                content: upload.fileData,
                                side: upload.side
                            };

                            const documentImage = await this.addDocumentImage(registration, document, image);

                            if(Object.keys(documentImage).length > 0) {
                                await this.animateProgress({
                                    width: '100%',
                                    hideProgress: true,
                                    hideNotice: true,
                                    delay: 1000,
                                    side: upload.side
                                });
                            }
                        }
                    }
                }

                resolve({
                    upload: upload,
                    document: document
                });
            });
        }
    };

    removeQueueItem = (queueIdentifier, queueKey) => {
        return this.props.dispatch(removeFromQueue({
            identifier: queueIdentifier,
            queueKey: queueKey
        }));
    };

    runDocument = async (registration, document) => {
        if(registration.registration_id === undefined || registration.registration_id === '') {
            // throw error
            console.log('error');
        }

        if(document.document_id === undefined || document.document_id === '') {
            // throw error
            console.log('error');
        }

        const callObject = {
            method: "POST",
            urlExtension: "/registrations/" + registration.registration_id + "/document/" + document.document_id + "/run"
        };

        return new Promise((resolve, reject) => {
            sendRequest(callObject).then((response) => {
                resolve('RUN-COMPLETED');
            })
        });
    };

    addDocumentImage = async (registration, document, image) => {
        if(registration.registration_id === undefined || registration.registration_id === '') {
            // throw error
            console.log('error');
        }

        const callObject = {
            method: "POST",
            urlExtension: "/registrations/" + registration.registration_id + "/document/" + document.document_id + "/image",
            properties: {
                image: image
            }
        };

        return new Promise((resolve, reject) => {
            sendRequest(callObject).then(res => res.json())
                .then(json => {
                    resolve(json);
                });
        });
    };

    openNavigation = () => {
        this.setState({
            showNav: true
        });
    };

    closeNavigation = () => {
        this.setState({
            showNav: false
        });
    };

    logOut = () => {
        localStorage.setItem('AUTH-USER', '{}');
        localStorage.setItem('AUTH-TOKEN', '');

        this.props.dispatch(removeUser());
        this.props.history.push('/login');
    };

    render() {

        let menuClass = '';

        if(this.state.showNav === true) {
            menuClass = ' app__menu--visible'
        }

        return (
            <div>
                <header className="header">
                    <div className="header__title">
                        {this.props.title}
                    </div>
                    <div className="header__menu" onClick={this.openNavigation}>
                        <span>&nbsp;</span>
                    </div>
                </header>
                <div className={'app__menu' + menuClass}>
                    <div className="app__menu__inner">
                        <div className="app__menu__close" onClick={this.closeNavigation}>
                            <span>Menu</span>
                        </div>
                        <ul className="app__menu__list">
                            <li onClick={() => this.props.history.push('/process')}><span className={this.props.match.path === '/process' ? 'active' : ''}>Proces keuze</span></li>
                            <li onClick={() => this.props.history.push('/privacy')}><span>Privacy verklaring</span></li>
                            <li onClick={this.logOut}><span>Uitloggen</span></li>
                        </ul>
                    </div>
                </div>
                <div className="progress progress--completing progress--front">
                    <div className="progress__indicator">
                        <span></span>
                    </div>
                    <div className="progress__label">Verwerken...</div>
                </div>
                <div className="notice notice--default notice--front">
                    <div className="notice__message">
                        Voorzijde document word geupload
                    </div>
                </div>
                <div className="progress progress--completing progress--back">
                    <div className="progress__indicator">
                        <span></span>
                    </div>
                    <div className="progress__label">Verwerken...</div>
                </div>
                <div className="notice notice--default notice--back">
                    <div className="notice__message">
                        Het document word verwerkt <br/>
                        Wilt u nog extra gegevens toevoegen?
                    </div>
                </div>
            </div>
        );
    };
}

const MapStateToProps = (state) => ({
    user: state.user,
    company: state.company,
    queue: state.queue,
    sets: state.sets,
    documents: state.documents,
    registration: state.registration
});

export default connect(MapStateToProps)(withRouter(HeaderDefault));
