import React from 'react';

function componentWrapper(WrappedComponent, HeaderProps) {
    return class extends React.Component {

        constructor(props) {
            super(props);

            this.initialState = {};

            this.state = this.initialState;
        }

        render() {
            const noDefaultHeaderPages = ['/', '/login', '/login/:identifier', '/forgot-password', '/privacy'];
            const cancelHeaderPages = ['/forgot-password', '/privacy'];
            const noHeaderPages = ['/instructions'];
            let redirectToLogin = false;

            if(HeaderProps.isLoggedIn === false && noDefaultHeaderPages.indexOf(this.props.match.path) === -1) {
                redirectToLogin = true;
            }

            if(redirectToLogin === true) {
                let redirectPath = '/login';

                if(localStorage.getItem('LAST-IDENTIFIER') !== null && localStorage.getItem('LAST-IDENTIFIER') !== '') {
                    redirectPath += '/' + localStorage.getItem('LAST-IDENTIFIER');
                }

                this.props.history.push(redirectPath);
            }

            const showHeader = HeaderProps.isLoggedIn === true && noDefaultHeaderPages.indexOf(this.props.match.path) === -1;
            const showCancelHeader = cancelHeaderPages.indexOf(this.props.location.pathname) !== -1;
            const noHeader = noHeaderPages.indexOf(this.props.location.pathname) !== -1;

            let header = null;

            if(showHeader === true) {
                header = 'HeaderDefault';
            }

            if(showCancelHeader === true) {
                header = 'HeaderCancel';
            }

            if(noHeader === true) {
                header = null;
            }

            return (
                <WrappedComponent {...HeaderProps} header={header}/>
            );
        }
    }
}

export default componentWrapper;