import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Header from "./Header";

class Password extends Component {

    render() {
        const foundCompany = this.props.company !== undefined && Object.keys(this.props.company).length > 0;
        const layoutSettings = this.props.company.layoutSettings;

        return(
            <div className="app">
                <Header {...this.props} />
                <div className="install">
                    <div className="install__background">
                        {
                            foundCompany &&
                            <div className="login__background">
                                <img src={layoutSettings.urlImageBgLogin} alt="Background"/>
                            </div>
                        }
                    </div>
                    <div className="install__inner">
                        <div className="install__top">
                            <div className="install__top__logo">
                                {
                                    foundCompany &&
                                    <div>
                                        <img src={layoutSettings.urlImageLogoLogin} alt="Logo"/>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="install__center">
                            <div className="install__instructions">
                                <img src="img/assets/icons/icon-lock.svg" width="18" alt=""/> <br/><br/>
                                <b>
                                    Als u niet meer in de beschikking bent van uw huidige wachtwoord kunt u contact opnemen
                                    met de beheerder van uw organisatie.
                                    <br/><br/>
                                    U krijgt vervolgens een E-mail om een nieuw wachtwoord in te stellen.
                                    <br/><br/>
                                </b>
                            </div>

                            <div className="notice notice--default notice--alt notice--np">
                                <div className="notice__message">
                                    Dit proces kan alleen door de beheerder vericht worden
                                </div>
                            </div>
                        </div>
                        <div className="install__bottom">
                            <div className="poweredby">
                                <span>Powered by <img src="img/assets/login/24id.jpg" width="24" alt="ID-Check"/> <b>ID Check</b></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

const MapStateToProps = (state) => ({
    user: state.user,
    company: state.company
});

export default connect(MapStateToProps)(withRouter(Password));