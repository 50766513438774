import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {sendRequest} from '../helpers/Request';
import {setCompany} from "../store/actions/Company";
import {setUser} from "../store/actions/User";
import {setToken} from "../store/actions/User";
import Bowser from "bowser";
import {osName} from "react-device-detect";
import {setButtonStyle} from "../store/actions/Buttonstyle";
import {setOutlineStyle} from "../store/actions/Outlinestyle";

class Login extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            username: '',
            password: '',
            errors: [],
            user: '',
            showInstructions: false,
            operationSystem: '',
            browser: ''
        };

        this.state = this.initialState;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    checkEasterEggs = () => {
        return this.props.location.search === '?24rentaldev';
    };

    checkStandAlone() {
        const foundEasterEgg = this.checkEasterEggs();
        const browser = Bowser.getParser(window.navigator.userAgent);

        if(!window.matchMedia('(display-mode: standalone)').matches && foundEasterEgg === false) {
            this.setState({
                operationSystem: osName,
                browser: browser.getBrowser().name,
                showInstructions: true
            });
        }
	};

    getIdentifier = () => {
        let identifier = null;

        if(this.props.match.params.identifier !== '' && this.props.match.params.identifier !== undefined) {
            localStorage.setItem('LAST-IDENTIFIER', this.props.match.params.identifier);
            identifier = this.props.match.params.identifier;
        } else if(localStorage.getItem('LAST-IDENTIFIER') !== '' && localStorage.getItem('LAST-IDENTIFIER') !== undefined) {
            identifier = localStorage.getItem('LAST-IDENTIFIER');
        }

        return identifier;
    };

    componentDidMount() {
        const user = localStorage.getItem('AUTH-USER');
        const token = localStorage.getItem('AUTH-TOKEN');

        if(Object.keys(this.props.company).length === 0) {
            if(this.getIdentifier() !== null) {

                const callObject = {
                    method: "GET",
                    urlExtension: "/auth/organisation/" + this.getIdentifier()
                };

                sendRequest(callObject).then((response) => response.json())
                    .then(async(responseJson) => {
                        if(responseJson !== undefined) {
                            if(responseJson.layoutSettings !== undefined && Object.keys(responseJson.layoutSettings).length > 0) {
                                localStorage.setItem('AUTH-COMPANY', JSON.stringify(responseJson));

                                const buttonStyle = {};
                                const outlineStyle = {};

                                if(responseJson.layoutSettings !== undefined) {

                                    // buttonStyle
                                    if(responseJson.layoutSettings.colorButtonBgPrimary !== undefined) {
                                        buttonStyle['backgroundColor'] = '#' + responseJson.layoutSettings.colorButtonBgPrimary;
                                    }

                                    if(responseJson.layoutSettings.colorButtonTextPrimary !== undefined) {
                                        buttonStyle['color'] = '#' + responseJson.layoutSettings.colorButtonTextPrimary;
                                    }

                                    // outlineStyle
                                    if(responseJson.layoutSettings.colorButtonOutline !== undefined) {
                                        outlineStyle['border'] = '2px solid #' + responseJson.layoutSettings.colorButtonOutline;
                                    }

                                    this.props.dispatch(setButtonStyle(buttonStyle));
                                    this.props.dispatch(setOutlineStyle(outlineStyle));
                                }

                                this.props.dispatch(setCompany(responseJson));
                                this.rewriteManifest(responseJson.layoutSettings);
                            }
                            else {
                                this.props.history.push('/');
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            else {
                this.props.history.push('/');
            }
        }

        if(this.props.user.username === '') {
            if (user !== null && Object.keys(JSON.parse(user)).length > 0 && token !== null && token !== '') {
                this.props.dispatch(setUser(JSON.parse(user).username));
                this.props.dispatch(setToken(token));
            }
        }

        this.checkForRedirect();
        this.checkStandAlone();
    }

    rewriteManifest = (layoutSettings) => {
        const link = document.createElement('link');
        link.rel = "manifest";
        link.href = "manifest.json?v=2";
        document.head.appendChild(link);

        const myDynamicManifest = {
            "theme_color": "#FFFFFF",
            "background_color": "#FFFFFF",
            "display": "standalone",
            "orientation": "portrait",
            "scope": "http://localhost:3000/",
            //"scope": "https://pwa24rental.faceworks.nl/",
            //"scope": "https://pwa.24idcheck.com/"
        };

        if(layoutSettings.companyName !== undefined && layoutSettings.companyName.length > 0) {
            myDynamicManifest['name'] = layoutSettings.companyName;
            myDynamicManifest['short_name'] = layoutSettings.companyName;
            document.title = layoutSettings.companyName;
        }

        if(layoutSettings.icons !== undefined && layoutSettings.icons.length > 0) {
            myDynamicManifest['icons'] = layoutSettings.icons;
            this.setAppleTouchIcon(layoutSettings.icons[0]);
        }

        if(layoutSettings.urlImageIconFavicon !== undefined && layoutSettings.urlImageIconFavicon.length > 0) {
            this.setFavicon(layoutSettings.urlImageIconFavicon);
        }

        const stringManifest = JSON.stringify(myDynamicManifest);
        const blob = new Blob([stringManifest], {type: 'application/json'});
        const manifestURL = URL.createObjectURL(blob);
        document.querySelector('#manifest-id').setAttribute('href', manifestURL);
    };

    setFavicon = (faviconLocation) => {
        const link = document.createElement('link');
        link.rel = "shortcut icon";
        link.href = faviconLocation;
        document.head.appendChild(link);
    };

    setAppleTouchIcon = (icon) => {
        const link = document.createElement('link');
        link.rel = "apple-touch-icon";
        link.sizes = icon.sizes;
        link.href = icon.src;
        document.head.appendChild(link);
    };

    checkForRedirect = () => {
        if(this.props.user.username !== '' && window.matchMedia('(display-mode: standalone)').matches) {
            this.props.history.push('/process');
        }
    };

    submitForm = () => {
    	const {username, password} = this.state;

    	let errors = [];
    		
    	if (password === '') {
    		errors = [...errors, "Wachtwoord ontbreekt"];
    	}
    	
    	if (username === '') {
    		errors = [...errors, "Gebruikersnaam ontbreekt"];
    	}

    	this.setState({errors: errors});

    	if (errors.length === 0) {
    		this.handleSubmit(this.state);
    	}
    };
    
    handleChange = event => {
        const {name, value} = event.target;

        this.setState({
            [name] : value
        });
    };
    
    handleSubmit () {
        const callObject = {
            method: "POST",
            urlExtension: "/auth/login/" + this.getIdentifier(),
            properties: {
                username: this.state.username,
                password: this.state.password,
            }
        };

        sendRequest(callObject).then((response) => response.json())
            .then((responseJson) => {

                if(responseJson.token !== undefined && responseJson.token !== '') {
                    this.props.dispatch(setToken(responseJson.token));
                    localStorage.setItem('AUTH-TOKEN', responseJson.token);
                }

                if (responseJson.user !== undefined) {
                    this.props.dispatch(setUser(responseJson.user));
                    localStorage.setItem('AUTH-USER', JSON.stringify(responseJson.user));
                    this.props.history.push('/process');
                }
                else {
                    this.setState({errors: [...this.state.errors, "Gebruikersnaam en/of wachtwoord incorrect"]});
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    render() {
        const {errors} = this.state;

        const foundCompany = this.props.company !== undefined && Object.keys(this.props.company).length > 0;
        const foundLayoutSettings = this.props.company.layoutSettings !== undefined && Object.keys(this.props.company.layoutSettings).length > 0;
        const layoutSettings = this.props.company.layoutSettings;

        let usernameError = false;
        let passwordError = false;
        let credentialsError = false;

        const errorList = errors.map((message, key) => {
            if(message.includes('Gebruikersnaam') === true) {
                usernameError = true;
            }

            if(message.includes('Wachtwoord') === true) {
                passwordError = true;
            }

            if(message.includes('incorrect') === true) {
                credentialsError = true;
            }

            return (
                <li key={key} className="form__error">
                    {message}
                </li>
            );
        });

        const instructions = {
            iOS: {
                Safari: {
                    /* title: 'Installeren van deze app:',
                    items: [
                        '<span class="install__instructions__item__number"><b>1.</b></span><div><span class="install__instructions__item__content">Druk op de "Delen"/"Share" knop in de navigatiebalk</span><div class="install__instructions__item__image"><img src="/img/assets/login/ios_step1.png"/></div></div>',
                        '<span class="install__instructions__item__number"><b>2.</b></span><div><span class="install__instructions__item__content">Kies de optie "Toevoegen aan beginscherm" / "Add to Home Screen"</span><div class="install__instructions__item__image"><img src="/img/assets/login/ios_step2.png"/></div></div>',
                        '<span class="install__instructions__item__number"><b>3.</b></span><div><span class="install__instructions__item__content">Druk rechtsboven op "Toevoegen" / "Add" om de app toe te voegen aan de telefoon</span><div class="install__instructions__item__image"><img src="/img/assets/login/ios_step3.png"/></div></div>',
                        '<span class="install__instructions__item__number"><b>4.</b></span><div><span class="install__instructions__item__content">Open de app op het beginscherm van de telefoon</span></div>'
                    ], */
                    forceSkipInstructions : true,
                    title : '',
                    items : [],
                }
            },
            Android: {
                Chrome: {
                    // title: 'Installeren van deze app:',
                    // items: [
                    //     '<span class="install__instructions__item__number"><b>1.</b></span><div><span class="install__instructions__item__content">Druk rechtsboven op het browsermenu</span><div class="install__instructions__item__image"><img src="/img/assets/login/android_step1.png"/></div></div>',
                    //     '<span class="install__instructions__item__number"><b>2.</b></span><div><span class="install__instructions__item__content">Kies de optie "Toevoegen aan beginscherm" / "Add to Home Screen"</span><div class="install__instructions__item__image"><img src="/img/assets/login/android_step2.png"/></div></div>',
                    //     '<span class="install__instructions__item__number"><b>3.</b></span><div><span class="install__instructions__item__content">In de pop-up, druk op "Toevoegen" / "Add"</span><div class="install__instructions__item__image"><img src="/img/assets/login/android_step3.png"/></div></div>',
                    //     '<span class="install__instructions__item__number"><b>4.</b></span><div><span class="install__instructions__item__content">Open de app op het beginscherm van de telefoon</span></div>'
                    // ]
                    forceSkipInstructions : true,
                    title : '',
                    items : [],
                }
            },
            Alternative: {
                forceSkipInstructions : false,
                titleWarning: 'Deze browser word niet ondersteund !!!',
                title: 'U kunt gebruik maken van de app middels een van de volgende browsers.',
                browserItems: [
                    '<div class="install__instructions__browser__item"><span class="install__instructions__browser__title"><b>Android Chrome</b></span><img src="/img/assets/login/chrome_logo.png" /></div>',
                    '<div class="install__instructions__browser__item"><span class="install__instructions__browser__title"><b>iOS Safari</b></span><img src="/img/assets/login/safari_logo.png" /></div>'
                ]
            }
        };

        let instructionObject = instructions.Alternative;

        if(instructions[this.state.operationSystem] !== undefined && instructions[this.state.operationSystem][this.state.browser] !== undefined) {
            instructionObject = instructions[this.state.operationSystem][this.state.browser];
        }
        
        let doShowInstructions = this.state.showInstructions;

        if(doShowInstructions === true 
            && instructionObject.forceSkipInstructions === true) {

            doShowInstructions = false;
        }

        return (
            <div className="app">
                {
                    doShowInstructions === false &&
                    <div className="login">
                        {
                            foundLayoutSettings &&
                            <div>
                                {
                                    foundCompany &&
                                    <div className="login__background">
                                        <img src={layoutSettings.urlImageBgLogin} alt=""/>
                                    </div>
                                }
                                <div className="login__components">
                                    <div className="login__top">
                                        {
                                            foundCompany &&
                                            <div className="login__logo">
                                                <img src={layoutSettings.urlImageLogoLogin} alt="Logo"/>
                                            </div>
                                        }
                                        <div className="login__form">
                                            {
                                                errors.length > 0 &&
                                                <div className="form__general-error-message">
                                                    <ul>
                                                        {errorList}
                                                    </ul>
                                                </div>
                                            }
                                            <div className={usernameError === true || credentialsError === true ? 'form__component form__component--error' : 'form__component'}>
                                                <div className="form__element form__element--username">
                                                    <input type="text" name="username" id="username" placeholder="Gebruikersnaam" onChange={this.handleChange} autoComplete="new-user"/>
                                                </div>
                                            </div>
                                            <div className={passwordError === true || credentialsError === true ? 'form__component form__component--error' : 'form__component'}>
                                                <div className="form__element form__element--password">
                                                    <input type="password" name="password" id="password" placeholder="Wachtwoord" onChange={this.handleChange} autoComplete="new-password"/>
                                                </div>

                                            </div>
                                            <div className="form__component">
                                                <div className="form__element form__element--submit">
                                                    {
                                                        foundCompany &&
                                                        <button className="button button--login" onClick={this.submitForm} style={{backgroundColor: '#' + layoutSettings.colorButtonBgLogin, color: '#' + layoutSettings.colorButtonTextLogin}}>Inloggen</button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form__component">
                                                <div className="form__element form__element--link">
                                                    <span onClick={() => this.props.history.push('/forgot-password')}>Wachtwoord vergeten?</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="login__bottom">
                                        <div className="login__poweredby">
                                            <div className="poweredby">
                                                <span>Powered by <img src="/img/assets/login/24id.jpg" width="24" alt="ID-Check"/> <b>ID Check</b></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {
                    doShowInstructions === true &&
                    <div className="install">
                        <div className="install__background">
                            {
                                foundCompany &&
                                <div className="login__background">
                                    <img src={layoutSettings.urlImageBgLogin} alt="Background"/>
                                </div>
                            }
                        </div>
                        <div className="install__inner">
                            <div className="install__top">
                                <div className="install__top__logo">
                                    {
                                        foundCompany &&
                                        <div>
                                            <img src={layoutSettings.urlImageLogoLogin} alt="Logo"/>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="install__center">
                                <div className="install__instructions">
                                    {
                                        instructionObject.titleWarning !== undefined && instructionObject.titleWarning.length > 0 &&
                                        <div className="install__instructions__title--warning"><b>{instructionObject.titleWarning}</b></div>
                                    }
                                    {
                                        instructionObject.title !== undefined && instructionObject.title.length > 0 &&
                                        <div className="install__instructions__title"><b>{instructionObject.title}</b></div>
                                    }

                                    {
                                        instructionObject.browserItems !== undefined && instructionObject.browserItems.length > 0 &&
                                        <div className="browser__container">
                                            {
                                                instructionObject.browserItems.map((item, key) => (
                                                    <div className="install__instructions__browser" key={key} dangerouslySetInnerHTML={{__html: item}} />
                                                ))
                                            }
                                        </div>
                                    }
                                    {
                                        instructionObject.items !== undefined && instructionObject.items.length > 0 &&
                                        <div className="item__container">
                                            {
                                                instructionObject.items.map((item, key) => (
                                                    <div className="install__instructions__item" key={key} dangerouslySetInnerHTML={{__html: item}} />
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="install__bottom">
                                <div className="poweredby">
                                    <span>Powered by <img src="/img/assets/login/24id.jpg" width="24" alt="ID-Check"/> <b>ID Check</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    };
}

const MapStateToProps = (state) => ({
    user: state.user,
    company: state.company
});

export default connect(MapStateToProps)(withRouter(Login));

