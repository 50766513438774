export const setUser = (user) => ({
    type: 'SET_USER',
    username: user.username
});

export const removeUser = () => ({
    type: 'REMOVE_USER',
    username: ''
});

export const setToken = (token) => ({
    type: 'SET_TOKEN',
    token: token
});

export const setHost = (host) => ({
    type: 'SET_HOST',
    host: host
});