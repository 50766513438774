export default async function readFile(file) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    var promise = new Promise((resolve, reject) => {
        fileReader.onload = function() {
            resolve(fileReader.result);
        };
        fileReader.onerror = function() {
            reject(false);
        }
    });

    await promise;
    return promise.then((value) => {
        return value;
    });
}