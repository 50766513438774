const initialState = {
	"available" : [],
	"selected": {}
};

export default (state = initialState, action) => {
    switch(action.type) {
    
        case 'SET_SETLIST':
            return action.sets;
            
        default:
            return state;
    }
};