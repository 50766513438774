import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class HeaderCancel extends Component {

    constructor(props) {
        super(props);

        this.initialState = {};

        this.state = this.initialState;
    };

    render() {
        return (
            <div>
                <header className="header">
                    <div className="header__title">
                        {this.props.title}
                    </div>

                    <div className="header__close" onClick={() => this.props.history.goBack()}>
                        <span>&nbsp;</span>
                    </div>
                </header>
            </div>
        );
    };
}

const MapStateToProps = (state) => ({
    user: state.user,
    company: state.company,
    queue: state.queue,
    sets: state.sets,
    documents: state.documents,
    registration: state.registration
});

export default connect(MapStateToProps)(withRouter(HeaderCancel));
