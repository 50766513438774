import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import Header from "./Header";

class Controls extends Component {

    render() {
        const resultObject = {
            'unknown': 'Onbekend',
            'ok': 'Geaccepteerd',
            'manual': 'Handmatige validatie',
            'no_pass': 'Afgekeurd',
            'expired': 'Verlopen'
        };

        const acceptationCheck = this.props.registration.acceptation !== undefined && Object.keys(this.props.registration.acceptation).length > 0;
        const testsCheck = acceptationCheck === true && this.props.registration.acceptation.tests !== undefined && this.props.registration.acceptation.tests.length > 0;

        return(
            <div className="app">
                <Header {...this.props} />
                <div className="app__top">
                    {
                        acceptationCheck &&
                        <div>
                            <div className={"result result--" + this.props.registration.acceptation.total_result}>
                                <div className="result__inner">
                                    <span>Status</span>
                                    <span>{resultObject[this.props.registration.acceptation.total_result]}</span>
                                </div>
                            </div>
                            <div className="table">
                                <table className="table__data">
                                    <thead>
                                    <tr>
                                        <td>Bron</td>
                                        <td style={{textAlign: 'right'}}>Resultaat</td>
                                    </tr>
                                    </thead>
                                    {
                                        testsCheck &&
                                        <tbody>
                                        {
                                            this.props.registration.acceptation.tests.map((test, key) => {

                                                return(
                                                    <tr key={key} className={"table__data__" + test.result}>
                                                        <td className="table__data__label">{test.display_name}</td>
                                                        {
                                                            test.result !== undefined && test.result !== '' && resultObject[test.result] !== undefined &&
                                                            <td className="table__data__value" style={{textAlign: 'right'}}>
                                                                {
                                                                    test.result_message === undefined &&
                                                                    resultObject[test.result]
                                                                }
                                                                {
                                                                    test.result_message !== undefined && test.result_message.length > 0 &&
                                                                    <span style={{display: 'block'}}>{test.result_message}</span>
                                                                }
                                                            </td>
                                                        }
                                                    </tr>
                                                );
                                            })
                                        }
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    }
                </div>
                <div className="app__bottom">
                    <div className="buttons">
                        <span className="button" style={this.props.buttonStyle} onClick={() => this.props.history.push('/result')}>Terug naar resultaat</span>
                        <span className="button button--outline" style={this.props.outlineStyle} onClick={() => this.props.history.push('/process')}>Afsluiten</span>
                    </div>
                </div>
            </div>
        );
    };
}

const MapStateToProps = (state) => ({
    user: state.user,
    buttonStyle: state.buttonStyle,
    outlineStyle: state.outlineStyle,
    queue: state.queue,
    sets: state.sets,
    documents: state.documents,
    registration: state.registration
});

export default connect(MapStateToProps)(withRouter(Controls));
