import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {sendRequest} from '../helpers/Request';
import {setSetList} from "../store/actions/Sets";
import {setDocuments} from "../store/actions/Documents";
import {clearRegistration, setRegistration} from "../store/actions/Registration";
import Header from "./Header";
import loaderGif from "../img/assets/icons/loading_bolletjes_black.gif";

class Process extends Component {
	_isMounted = false;

    constructor(props) {
        super(props);

        this.initialState = {
        	hideSets: true,
			recentRegistration: {}
		};

        this.state = this.initialState;
    }

    componentDidMount() {
    	this._isMounted = true;

    	setTimeout(async() => {
    		if(this._isMounted) {
				await this.clearState();
				await this.checkRecent();
				await this.getAcceptationSets();
				this.checkForSets();
			}
		}, 1000);
    }

	componentWillUnmount() {
		this._isMounted = false;
	}
    
    handleProcess = async (set_id) => {

    	const documents = await this.setSelected(set_id);

    	if(documents.available.length > 0) {
			this.props.history.push(this.props.location.pathname + '/documents/front');
		}
		else {
			this.props.history.push(this.props.location.pathname + '/form/extraInfo');
		}
    };

    checkForSets = () => {
    	if(this.props.sets.available.length > 0 && this._isMounted === true) {
    		this.setState({
				hideSets: false
			});
		}
	};
    
	getAcceptationSets = () => {

	    const callObject = {
	        method: "GET",
	        urlExtension: "/acceptation"
	    };

		return new Promise(async(resolve) => {
			const response = await sendRequest(callObject);
			const data = await response.json();

			if(data.status !== 404 && data.sets !== undefined) {
				const sets = {
					"selected" : "",
					"available": data.sets
				};

				localStorage.setItem('REGISTRATION-SETS', JSON.stringify(sets));

				this.props.dispatch(setSetList(sets));

				const documents = data.document_config;
				documents.available = [];
				documents.selected = {};

				localStorage.setItem('REGISTRATION-DOCUMENTS', JSON.stringify(documents));

				this.props.dispatch(setDocuments(documents));

				resolve(data.sets);
			}
		});
    };
    
    clearState = () => {
		return new Promise((resolve) => {
			this.props.dispatch(clearRegistration());
			localStorage.setItem('REGISTRATION-DATA', JSON.stringify({}));
			resolve('OK');
		});
    };
    
    setSelected = (set_id) => {
		const sets = this.props.sets;
		const documents = this.props.documents;

		documents.available = [];
		documents.completed = false;

		sets.available.forEach(function(set) {
			if (set.set_id === set_id) {
				sets.selected = set;

				if(set.document_types_supported !== undefined && set.document_types_supported.length > 0) {
					set.document_types_supported.forEach(function(document_type) {
						if (documents.types !== undefined && documents.types.length > 0) {
							documents.types.forEach(function(document) {

								if (document.document_type === document_type) {

									document.required = false;

									set.tests.forEach(function (test) {

										if (test.data_fields_required !== undefined) {
											test.data_fields_required.forEach(function (required_field) {

												if (required_field.tags !== undefined) {
													required_field.tags.forEach(function (tag) {

														if (tag === "document_column." + document.column) {
															document.required = true;
														}
													});
												}
											});
										}
									});

									documents.available = [...documents.available, document];
								}
							});
						}
					});
				}
			}
		});

	    const storedSets = this.props.dispatch(setSetList(sets));
	    localStorage.setItem('REGISTRATION-SETS', JSON.stringify(storedSets));

		const storedDocuments = this.props.dispatch(setDocuments(documents));
		localStorage.setItem('REGISTRATION-DOCUMENTS', JSON.stringify(storedDocuments));

	    return documents;
    };

    checkRecent = () => {
		const callObject = {
			method: "GET",
			urlExtension: "/registrations/recent"
		};

		return new Promise((resolve) => {
			sendRequest(callObject).then((response) => response.json())
				.then((responseJson) => {
					if(responseJson !== undefined) {
						if(responseJson.status !== 404 && responseJson.registration !== undefined) {
							if(this._isMounted) {
								this.setState({
									recentRegistration: responseJson.registration
								});
							}
						}
					}
				})
				.catch((error) => {
					console.error(error);
				});

			resolve('OK');
		});
	};

    goRecentRegistration = () => {
    	this.props.dispatch(setRegistration(this.state.recentRegistration));
		this.props.history.push(this.props.location.pathname.replace('process', 'result'));
	};

    render() {
        return(
        	<div className="app">
				<Header {...this.props} />
	            <div className="app__top">
	                <div className="buttons buttons--center">
						{
							this.state.hideSets === false && this.props.sets.available.length > 0 &&
							<div className="buttons--title">
								<span>Kies hier uw acceptatie proces</span>
							</div>
						}
					   	{
					   		this.state.hideSets === false && this.props.sets.available.length > 0 &&
						   	this.props.sets.available.map((item, key) => {
								return(
									<span key={key} className="button button--upload" style={this.props.buttonStyle} onClick={() => this.handleProcess(item.set_id)}>{item.display_name}</span>
								);
							})
					   	}
						{
							this.state.hideSets === true &&
							<div style={{"width": "100%"}} ><img src={loaderGif} alt="" style={{"width": "50px", "margin": "0 auto"}}/></div>
						}
	                </div>
	            </div>
                <div className="app__bottom">
                    <div className="buttons">
						{
							Object.keys(this.state.recentRegistration).length > 0 &&
							<span className="button" style={this.props.buttonStyle} onClick={() => this.goRecentRegistration()}>Laatste account</span>
						}
                    </div>
                </div>
            </div>
        );
    };			
}

const MapStateToProps = (state) => ({
	user: state.user,
	company: state.company,
	buttonStyle: state.buttonStyle,
	outlineStyle: state.outlineStyle,
	queue: state.queue,
	sets: state.sets,
	documents: state.documents,
	registration: state.registration
});

export default connect(MapStateToProps)(withRouter(Process));
