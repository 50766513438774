import React from "react";

const page404 = () => (
    <div className="app">
        <div>
            <header className="header">
                <div className="header__title">
                    Welkom bij 24ID Check
                </div>
            </header>
        </div>
        <div className="invalid">
            <div className="invalid__background">
                <img src="/img/assets/24idcheck-background.jpg" />
            </div>
            <div className="invalid__inner">
                <div className="invalid__center">
                    <div className="invalid__instructions">
                        <b>Ongeldige link</b><br />
                        <span>Deze link voor het downloaden van een PWA is ongeldig, ga na bij de uitgever van de link voor de correcte link.</span><br /><br />
                        <span>Voor meer informatie kunt u contact opnemen met de servicedesk.</span><br/>
                        <span>Telefoon 085 732 55 24 of email info@24idcheck.com</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default page404;