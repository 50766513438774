import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import Header from "./Header";

class Privacy extends Component {

    render() {
        return(
            <div className="app">
                <Header {...this.props} />
                <div className="app__top">
                    <div className="privacy">
                        <div className="privacy__section">
                            <div className="privacy__section__title">&nbsp;</div>
                            <div className="privacy__section__content">
                                Deze app werkt met persoonsgegevens van derden. Gebruik deze app zorgvuldig en correct.
                                Gebruik de app uitsluitend voor het doel waarvoor deze beschikbaar is gesteld en neem alle voorgeschreven voorzorgsmaatregelen in acht.
                                Meer informatie over de applicatie behorende bij deze app is terug te vinden in de applicatie zelf.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="app__bottom">&nbsp;</div>
            </div>
        );
    };
}

export default withRouter(Privacy);