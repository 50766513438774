import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import Header from "../Header";
import moment from "moment";
import {updateRegistration} from "../../store/actions/Registration";
import {checkAddress} from "../../store/actions/Address";
import Loader from "react-loader-spinner";
import $ from "jquery";

class EditResult extends Component {
    addressInterval = 0;

    constructor(props) {
        super(props);

        this.initialState = {
            requiredFields: {
                person: {
                    initials: 'Initialen zijn noodzakelijk',
                    first_name: 'Voornaam is noodzakelijk',
                    last_name: 'Achternaam is noodzakelijk',
                    date_of_birth: 'Geboortedatum is noodzakelijk'
                },
                address: {}
            },
            disabledFields: {
                person: {},
                address: {},
                documents: []
            },
            form: {
                person: {
                    initials: '',
                    first_name: '',
                    last_name_prefix: '',
                    last_name: '',
                    phone_number: '',
                    mail_address: '',
                    date_of_birth: {
                        day: '',
                        month: '',
                        year: ''
                    }
                },
                address: {
                    zip_code: '',
                    house_number: '',
                    house_number_extension: '',
                    street: '',
                    city: '',
                    country: ''
                },
                documents: this.props.registration.documents
            },
            errors: {
                person: {},
                address: {}
            },
            setSettings: {}
        };

        this.state = this.initialState;

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        $('.button--upload').hide();
        $('.form--loader').show();

        $('input').focus(function() {
            $('html, body').animate({
                scrollTop: ($(this).offset().top - 35)
            }, 500);
        });

        const registration = this.props.registration;
        const personArray = ['initials', 'first_name', 'last_name_prefix', 'last_name', 'phone_number', 'mail_address', 'date_of_birth'];
        const addressArray = ['zip_code', 'house_number', 'house_number_extension', 'street', 'city', 'country'];
        const form = {
            person: {
                date_of_birth: {}
            },
            address: {},
            documents: this.props.registration.documents
        };

        if(registration.person !== undefined && Object.keys(registration.person).length > 0) {

            personArray.forEach(function(field) {
                if(registration.person[field] !== undefined && registration.person[field] !== '') {
                    if(field === 'date_of_birth') {
                        form.person[field]['day'] = moment(registration.person[field]).format('DD');
                        form.person[field]['month'] = moment(registration.person[field]).format('MM');
                        form.person[field]['year'] = moment(registration.person[field]).format('YYYY');
                    } else {
                        form.person[field] = registration.person[field];
                    }
                } else {
                    if(field === 'date_of_birth') {
                        form.person[field]['day'] = '';
                        form.person[field]['month'] = '';
                        form.person[field]['year'] = '';
                    } else {
                        form.person[field] = '';
                    }
                }
            });
        }

        if(registration.address !== undefined && Object.keys(registration.address).length > 0) {

            addressArray.forEach(function(field) {
                if(registration.address[field] !== undefined && registration.address[field] !== '') {
                    form.address[field] = registration.address[field];
                } else {

                    if(field === 'country') {
                        form.address[field] = 'NL';
                    }
                    else {
                        form.address[field] = '';
                    }
                }
            });
        }

        this.setState(() => ({form: form}), () => {
            this.addressInterval = setTimeout(() => {this.checkAddress('house_number')}, 500);
        });

        this.setRequiredFields();
    };

    setRequiredFields = () => {
        if(this.props.registration !== undefined && this.props.registration.acceptation !== undefined) {
            const setId = this.props.registration.acceptation.set_id;
            let setSettings = null;

            if (setId !== undefined && this.props.sets.available.length > 0) {
                const availableSets = this.props.sets.available;

                availableSets.forEach(function (set) {
                    if (set.set_id === setId && set.set_settings !== undefined && Object.keys(set.set_settings).length > 0) {
                        setSettings = set.set_settings;
                    }
                });
            }

            if(setSettings !== null && Object.keys(setSettings).length > 0) {
            	
                if(setSettings.is_email_required !== undefined && setSettings.is_email_required === true) {

                    this.setState((state) => ({
                        ...state,
                        requiredFields: {
                            ...state.requiredFields,
                            person: {
                                ...state.requiredFields.person,
                                mail_address: 'Emailadres is noodzakelijk'
                            }
                        }
                    }));
                }

                if(setSettings.is_phone_required !== undefined && setSettings.is_phone_required === true) {

                    this.setState((state) => ({
                        ...state,
                        requiredFields: {
                            ...state.requiredFields,
                            person: {
                                ...state.requiredFields.person,
                                phone_number: 'Mobiele nummer is noodzakelijk'
                            }
                        }
                    }));
                }

                if(setSettings.is_address_required !== undefined && setSettings.is_address_required === true) {

                    this.setState((state) => ({
                        ...state,
                        requiredFields: {
                            ...state.requiredFields,
                            address: {
                                ...state.requiredFields.address,
                                zip_code: 'Postcode is noodzakelijk',
                                house_number: 'Huisnummer is noodzakelijk',
                                country: 'Land is noodzakelijk',
                                street: 'Straat is noodzakelijk',
                                city: 'Plaats is noodzakelijk'
                            }
                        }
                    }));
                }

                this.setState({
                    setSettings: setSettings
                });
            }
        }
    };

    handleChange = (event, field, formKey, subFieldKey = null) => {
        let {value} = event.target;
        const fieldData = {
            person: this.state.form.person,
            address: this.state.form.address,
            documents: this.state.form.documents
        };

        if(value !== undefined) {
            const newValue = this.checkFieldValidation(field, value, formKey, subFieldKey);

            if(formKey === 'documents') {
                fieldData[formKey][subFieldKey][field] = newValue;
            } else {
                if(subFieldKey !== null) {
                    fieldData[formKey][field][subFieldKey] = newValue;
                } else {
                    fieldData[formKey][field] = newValue;
                }
            }
        }

        let form = {...this.state.form, ...fieldData};

        this.setState(() => ({form: form}), () => {
            this.addressInterval = setTimeout(() => {this.checkAddress(field)}, 500);
        });
    };

    checkAddress = async (field) => {
        let zip_code = '';
        let house_number = '';
        let country = '';

        if(field === 'zip_code' || field === 'house_number' || field === 'country') {
            $('.button--upload').hide();
            $('.form--loader').show();

            if (this.state.form.address.zip_code !== undefined && this.state.form.address.zip_code !== '' && this.state.form.address.house_number !== undefined && this.state.form.address.house_number !== '' && this.state.form.address.country !== undefined && this.state.form.address.country !== '') {
                zip_code = this.state.form.address.zip_code;
                house_number = this.state.form.address.house_number;
                country = this.state.form.address.country;
            }

            if (zip_code.length > 0 && house_number.length > 0 && country.length > 0) {
                const address = await this.props.dispatch(checkAddress(zip_code, house_number, country));
                const fieldsArray = ['street', 'city', 'country'];

                if(address.address === null) {
                    fieldsArray.forEach((field) => {
                        if (this.state.disabledFields.address[field] !== undefined && this.state.disabledFields.address[field] === 'disabled') {
                            delete this.state.disabledFields.address[field];

                            this.setState((state) => ({
                                ...state,
                                form: {
                                    ...state.form,
                                    address: {
                                        ...state.form.address,
                                        street: '',
                                        city: ''
                                    }
                                }
                            }));
                        }
                    });
                }
                else if(Object.keys(address.address).length > 0) {
                    const addressObject = address.address;
                    let validAddress = true;

                    fieldsArray.forEach(function(field) {
                        if(addressObject[field] === undefined || addressObject[field] === '') {
                            validAddress = false;
                        }
                    });

                    if(validAddress === true) {
                        addressObject['city'] = addressObject['city'].toUpperCase();

                        if(addressObject['house_number_extension'] === null) {
                            addressObject['house_number_extension'] = '';
                        }

                        this.setState((state) => ({
                            ...state,
                            form: {
                                ...state.form,
                                address: addressObject
                            }
                        }));

                        fieldsArray.forEach((field) => {
                            if (this.state.disabledFields.address[field] === undefined && this.state.form.address[field] !== undefined && this.state.form.address[field] !== '' && field !== 'country') {
                                this.setState((state) => ({
                                    ...state,
                                    disabledFields: {
                                        ...state.disabledFields,
                                        address: {
                                            ...state.disabledFields.address,
                                            [field]: 'disabled'
                                        }
                                    }
                                }));
                            }
                        });
                    } else {
                        fieldsArray.forEach((field) => {
                            if (this.state.disabledFields.address[field] === undefined && this.state.disabledFields.address[field] === '') {
                                delete this.state.disabledFields.address[field];

                                this.setState((state) => ({
                                    ...state,
                                    form: {
                                        ...state.form,
                                        address: {
                                            ...state.form.address,
                                            [field]: ''
                                        }
                                    }
                                }));
                            }
                        });
                    }
                }
            }
        }

        $('.form--loader').hide();
        $('.button--upload').show();
    };

    clearErrors = () => {
        this.setState(() => ({
            errors: {
                person: {},
                address: {}
            }
        }));
    };

    setFieldError = (field, formKey, error) => {
        this.setState((state) => ({
            ...state,
            errors: {
                ...state.errors,
                [formKey]: {
                    ...state.errors[formKey],
                    [field]: error
                }
            }
        }));
    };

    extraFieldValidation = (field, formKey) => {
        switch(field) {
            case 'phone_number':
                const phoneRegex = /^\d{10}$/;
                const phoneValidation = phoneRegex.test(this.state.form[formKey][field]);

                if(phoneValidation === false) {
                    this.setFieldError(field, formKey, 'Mobiele nummer is onjuist');
                    return false;
                }

                return true;
            case 'mail_address':
                const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                const emailValidation = emailRegex.test(this.state.form[formKey][field]);

                if(emailValidation === false) {
                    this.setFieldError(field, formKey, 'Emailadres is onjuist');

                    return false;
                }

                return true;
            default:
                return true;
        }
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        $('.button--upload').hide();
        $('.form--loader').show();

        this.clearErrors();
        let errorCount = 0;

        const form = this.state.form;
        const requiredFields = this.state.requiredFields;
        const errors = this.state.errors;

        let transferBirthdate = true;

        Object.keys(form.person.date_of_birth).forEach(function(key) {
            if(form.person.date_of_birth[key] === undefined || form.person.date_of_birth[key] === '') {
                transferBirthdate = false;
            }
        });

        let birthDate = '';

        if(transferBirthdate === true) {
            birthDate = form.person.date_of_birth['year'] + '-' + form.person.date_of_birth['month'] + '-' + form.person.date_of_birth['day'];
        }

        Object.keys(form).forEach((formKey) => {
            if(Object.keys(form[formKey]).length > 0) {
                Object.keys(form[formKey]).forEach((key) => {
                    if ((form[formKey][key] !== undefined && form[formKey][key] !== '')) {
                        const extraFieldValidation = this.extraFieldValidation(key, formKey);

                        if (extraFieldValidation === false) {
                            errorCount++;
                        }
                    }
                });
            }
        });

        Object.keys(requiredFields).forEach((formKey) => {
            if(Object.keys(requiredFields[formKey]).length > 0) {
                Object.keys(requiredFields[formKey]).forEach((key) => {
                    if(key === 'date_of_birth') {
                        if(birthDate === undefined || birthDate === '') {
                            this.setFieldError(key, formKey, requiredFields[formKey][key]);
                            errorCount++;
                        } else {
                            if (errors[formKey] !== undefined && errors[formKey][key] !== undefined && errors[formKey][key] !== '') {
                                delete this.state.errors[formKey][key];
                            }
                        }
                    } else {
                        if (form[formKey][key] === undefined || form[formKey][key] === '') {
                            this.setFieldError(key, formKey, requiredFields[formKey][key]);
                            errorCount++;
                        } else {
                            if (errors[formKey] !== undefined && errors[formKey][key] !== undefined && errors[formKey][key] !== '') {
                                delete this.state.errors[formKey][key];
                            }
                        }
                    }
                });
            }
        });

        if(errorCount === 0) {
            form.person.date_of_birth = birthDate;

            await this.props.dispatch(updateRegistration(this.props.registration, form));

            $('.form--loader').hide();
            $('.button--upload').show();

            this.props.history.push('/result');
        } else {
            $('.form--loader').hide();
            $('.button--upload').show();
        }
    };

    checkFieldValidation = (field, value, formKey, subFieldKey) => {
        let currentValue = null;

        if(formKey === 'documents') {
            currentValue = this.state.form[formKey][subFieldKey][field];
        } else {
            if(subFieldKey !== null) {
                currentValue = this.state.form[formKey][field][subFieldKey];
            } else {
                currentValue = this.state.form[formKey][field];
            }
        }

        switch(field) {
            case 'initials':
                var string = '';
                var initials = value.toUpperCase().replace(/[^A-Z]/g, '').split('');

                if(initials.length > 0) {
                    if (currentValue !== undefined && currentValue !== '') {
                        if(value.length === 1) {
                            initials = [];
                        } else if (value.length < currentValue.length) {
                            for (var firstIndex in initials) {
                                if ((parseInt(firstIndex) + 1) !== value.toUpperCase().replace(/[^A-Z]/g, '').split('').length) {
                                    delete initials[initials.length - 1];
                                }
                            }
                        }

                        for (var secondIndex in initials) {
                            var initial = initials[secondIndex];
                            string += initial + '.';
                        }
                    } else {
                        string = initials + '.';
                    }
                }

                return string;

            case 'first_name':
                return this.ucWords(value);

            case 'last_name_prefix':
                return value.toLowerCase();

            case 'last_name':
                return this.ucWords(value);

            case 'date_of_birth':
                return value.replace(/\D/g, '');

            case 'phone_number':
                return value.replace(/\D/g, '');

            case 'mail_address':
                return value.toLowerCase();

            case 'zip_code':
                return value.toUpperCase().replace(' ', '', value);

            case 'house_number':
                return value.replace(/\D/g, '');

            case 'house_number_extension':
                return value.toUpperCase();

            case 'street':
                return this.ucWords(value);

            case 'city':
                return value.toUpperCase();

            case 'number':
                return value.replace(/\D/g, '');

            default:
                return value;
        }
    };

    ucWords = (value) => {
        var tempLastName = '';
        var parts = value.split(/\s/g);
        for (var firstIndex in parts) {
            var part = parts[firstIndex];
            part = part.substr(0, 1).toUpperCase() + part.substr(1);
            if (tempLastName === '') {
                tempLastName = part;
            }
            else {
                tempLastName += ' ' + part;
            }
        }

        var name = '';
        var newparts = tempLastName.split(/-/g);
        for (var secondIndex in parts) {
            var newpart = newparts[secondIndex];
            newpart = newpart.substr(0, 1).toUpperCase() + newpart.substr(1);
            if (name === '') {
                name = newpart;
            }
            else {
                name += '-' + newpart;
            }
        }

        return name;
    };

    render() {
        const documentsObject = {
            'drivers_license': 'Rijbewijs',
            'passport': 'Paspoort',
            'id_card': 'ID-kaart'
        };

        let errorList = [];

        const setSettings = this.state.setSettings;

        Object.keys(this.state.errors).forEach((formKey) => {
            if(Object.keys(this.state.errors[formKey]).length > 0) {
                errorList.push(Object.values(this.state.errors[formKey]).map((message, key) => {
                    return (
                        <li key={formKey + '-' + key} className="form__error">
                            {message}
                        </li>
                    );
                }));
            }
        });

        return(
            <div className="app">
                <Header {...this.props} />
                {
                    this.props.registration !== undefined && Object.keys(this.props.registration).length > 0 &&
                    <div>
                        <div className="app__top">
                            <div className="notice notice--default">
                                <div className="notice__message">
                                    U kunt de gegevens aanpassen <br/>
                                    Hierna word een nieuwe controle uitgevoerd
                                </div>
                            </div>
                            {
                                errorList.length > 0 &&
                                <div className="form__extraInfo-error-message">
                                    <ul>
                                        {errorList}
                                    </ul>
                                </div>
                            }
                            {
                                setSettings !== undefined && Object.keys(setSettings).length > 0 &&
                                <form className="form" onSubmit={(e) => { this.handleSubmit(e) }}>
                                    <div className="form__component form__component--fieldset form__component--fieldset--sm-lg">
                                        <div className="form__component__column">
                                            <label className="form__label">Initialen {this.state.requiredFields['person']['initials'] !== undefined ? <span className="required">*</span> : ''}</label>
                                            <div className="form__element">
                                                <input type="text" value={this.state.form.person.initials} disabled={this.state.disabledFields.person.initials === 'disabled'} onChange={(event) => this.handleChange(event, 'initials', 'person')}/>
                                            </div>
                                        </div>
                                        <div className="form__component__column">
                                            <label className="form__label">Voornaam {this.state.requiredFields['person']['first_name'] !== undefined ? <span className="required">*</span> : ''}</label>
                                            <div className="form__element">
                                                <input type="text" value={this.state.form.person.first_name} disabled={this.state.disabledFields.person.first_name === 'disabled'} onChange={(event) => this.handleChange(event, 'first_name', 'person')}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__component form__component--fieldset form__component--fieldset--sm-lg">
                                        <div className="form__component__column">
                                            <label className="form__label">Tussenv. {this.state.requiredFields['person']['last_name_prefix'] !== undefined ? <span className="required">*</span> : ''}</label>
                                            <div className="form__element">
                                                <input type="text" value={this.state.form.person.last_name_prefix} disabled={this.state.disabledFields.person.last_name_prefix === 'disabled'} onChange={(event) => this.handleChange(event, 'last_name_prefix', 'person')}/>
                                            </div>
                                        </div>
                                        <div className="form__component__column">
                                            <label className="form__label">Achternaam {this.state.requiredFields['person']['last_name'] !== undefined ? <span className="required">*</span> : ''}</label>
                                            <div className="form__element">
                                                <input type="text" value={this.state.form.person.last_name} disabled={this.state.disabledFields.person.last_name === 'disabled'} onChange={(event) => this.handleChange(event, 'last_name', 'person')}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form__component form__component--fieldset form__component--fieldset--birthdate">
                                        <label className="form__label">Geboortedatum {this.state.requiredFields['person']['date_of_birth'] !== undefined ? <span className="required">*</span> : ''}</label>
                                        <div className="form__component__column">
                                            <div className="form__element">
                                                <input type="text" maxLength="2" value={this.state.form.person.date_of_birth.day} onChange={(event) => this.handleChange(event, 'date_of_birth', 'person', 'day')}/>
                                            </div>
                                        </div>
                                        <div className="form__component__column">
                                            <div className="form__element">
                                                <input type="text" maxLength="2" value={this.state.form.person.date_of_birth.month} onChange={(event) => this.handleChange(event, 'date_of_birth', 'person', 'month')}/>
                                            </div>
                                        </div>
                                        <div className="form__component__column">
                                            <div className="form__element">
                                                <input type="text" maxLength="4" value={this.state.form.person.date_of_birth.year} onChange={(event) => this.handleChange(event, 'date_of_birth', 'person', 'year')}/>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        setSettings.is_phone_required !== undefined && setSettings.is_phone_required === true &&
                                        <div className="form__component">
                                            <label className="form__label">Mobiele nummer {this.state.requiredFields['person']['phone_number'] !== undefined ? <span className="required">*</span> : ''}</label>
                                            <div className="form__element">
                                                <input type="text" value={this.state.form.person.phone_number} disabled={this.state.disabledFields.person.phone_number === 'disabled'} onChange={(event) => this.handleChange(event, 'phone_number', 'person')}/>
                                            </div>
                                        </div>
                                    }
                                    {
                                        setSettings.is_email_required !== undefined && setSettings.is_email_required === true &&
                                        <div className="form__component">
                                            <label className="form__label">Emailadres {this.state.requiredFields['person']['mail_address'] !== undefined ? <span className="required">*</span> : ''}</label>
                                            <div className="form__element">
                                                <input type="text" value={this.state.form.person.mail_address} disabled={this.state.disabledFields.person.mail_address === 'disabled'} onChange={(event) => this.handleChange(event, 'mail_address', 'person')}/>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.form.documents.length > 0 &&
                                        this.state.form.documents.map((document, key) => {
                                            return(
                                                <div className="form__component form__component--fieldset form__component--fieldset--document" key={key}>
                                                    <div className="form__component__column">
                                                        <label className="form__label">Type</label>
                                                        <div className="form__element">
                                                            <input type="text" value={documentsObject[document.type]} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="form__component__column">
                                                        <label className="form__label">Documentnummer</label>
                                                        <div className="form__element">
                                                            <input type="text" value={this.state.form.documents[key]['number']} onChange={(event) => this.handleChange(event, 'number', 'documents', key)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    {
                                        setSettings.is_address_required !== undefined && setSettings.is_address_required === true &&
                                        <div>
                                            <div className="form__component">
                                                <label className="form__label">Land {this.state.requiredFields['address']['country'] !== undefined ? <span className="required">*</span> : ''}</label>
                                                <div className="form__element">
                                                    <select value={this.state.form.address.country} disabled={this.state.disabledFields.address.country === 'disabled'} onChange={(event) => this.handleChange(event, 'country', 'address')}>
                                                        <option value="">Maak uw keuze</option>
                                                        <option value="NL">Nederland</option>
                                                        <option value="BE">België</option>
                                                        <option value="DE">Duitsland</option>
                                                        <option value="GB">Verenigd Koninkrijk</option>
                                                        <option value="AF">Afghanistan</option>
                                                        <option value="AX">Åland</option>
                                                        <option value="AL">Albanië</option>
                                                        <option value="DZ">Algerije</option>
                                                        <option value="AS">Amerikaans-Samoa</option>
                                                        <option value="VI">Amerikaanse Maagdeneilanden</option>
                                                        <option value="AD">Andorra</option>
                                                        <option value="AO">Angola</option>
                                                        <option value="AI">Anguilla</option>
                                                        <option value="AQ">Antarctica</option>
                                                        <option value="AG">Antigua en Barbuda</option>
                                                        <option value="AR">Argentinië</option>
                                                        <option value="AM">Armenië</option>
                                                        <option value="AW">Aruba</option>
                                                        <option value="AU">Australië</option>
                                                        <option value="AZ">Azerbeidzjan</option>
                                                        <option value="BS">Bahama's</option>
                                                        <option value="BH">Bahrein</option>
                                                        <option value="BD">Bangladesh</option>
                                                        <option value="BB">Barbados</option>
                                                        <option value="BY">Belarus</option>
                                                        <option value="BZ">Belize</option>
                                                        <option value="BJ">Benin</option>
                                                        <option value="BM">Bermuda</option>
                                                        <option value="BT">Bhutan</option>
                                                        <option value="BO">Bolivië</option>
                                                        <option value="BQ">Bonaire, Sint Eustatius en Saba</option>
                                                        <option value="BA">Bosnië-Herzegovina</option>
                                                        <option value="BW">Botswana</option>
                                                        <option value="BV">Bouvet Eiland</option>
                                                        <option value="BR">Brazilië</option>
                                                        <option value="IO">Brits Indische oceaan</option>
                                                        <option value="VG">Britse Maagdeneilanden</option>
                                                        <option value="BN">Brunei</option>
                                                        <option value="BG">Bulgarije</option>
                                                        <option value="BF">Burkina Faso</option>
                                                        <option value="BI">Burundi</option>
                                                        <option value="KH">Cambodja</option>
                                                        <option value="CA">Canada</option>
                                                        <option value="KY">Caymaneilanden</option>
                                                        <option value="CF">Centraal-Afrikaanse Republiek</option>
                                                        <option value="XC">Ceuta</option>
                                                        <option value="CL">Chili</option>
                                                        <option value="CN">China</option>
                                                        <option value="CX">Christmaseiland</option>
                                                        <option value="CC">Cocos</option>
                                                        <option value="CO">Colombia</option>
                                                        <option value="KM">Comoren</option>
                                                        <option value="CD">Congo</option>
                                                        <option value="CG">Congo</option>
                                                        <option value="CK">Cook</option>
                                                        <option value="CR">Costa Rica</option>
                                                        <option value="CU">Cuba</option>
                                                        <option value="CW">Curaçao</option>
                                                        <option value="CY">Cyprus</option>
                                                        <option value="DK">Denemarken</option>
                                                        <option value="DJ">Djibouti</option>
                                                        <option value="DM">Dominica</option>
                                                        <option value="DO">Dominicaanse Republiek</option>
                                                        <option value="EC">Ecuador</option>
                                                        <option value="EG">Egypte</option>
                                                        <option value="IM">Eiland Man</option>
                                                        <option value="SV">El Salvador</option>
                                                        <option value="GQ">Equatoriaal-Guinea</option>
                                                        <option value="ER">Eritrea</option>
                                                        <option value="EE">Estland</option>
                                                        <option value="ET">Ethiopië</option>
                                                        <option value="EU">Europeese Gemeenschap</option>
                                                        <option value="FO">Faeröer</option>
                                                        <option value="FK">Falkland</option>
                                                        <option value="FJ">Fiji</option>
                                                        <option value="PH">Filipijnen</option>
                                                        <option value="FI">Finland</option>
                                                        <option value="FR">Frankrijk</option>
                                                        <option value="GF">Frans-Guyana</option>
                                                        <option value="PF">Frans-Polynesië</option>
                                                        <option value="TF">Franse Zuidelijke Gebieden</option>
                                                        <option value="GA">Gabon</option>
                                                        <option value="GM">Gambia</option>
                                                        <option value="GE">Georgië</option>
                                                        <option value="GH">Ghana</option>
                                                        <option value="GI">Gibraltar</option>
                                                        <option value="GD">Grenada</option>
                                                        <option value="GR">Griekenland</option>
                                                        <option value="GL">Groenland</option>
                                                        <option value="GP">Guadeloupe</option>
                                                        <option value="GU">Guam</option>
                                                        <option value="GT">Guatemala</option>
                                                        <option value="GG">Guernsey</option>
                                                        <option value="GN">Guinea</option>
                                                        <option value="GW">Guinee-Bissau</option>
                                                        <option value="GY">Guyana</option>
                                                        <option value="HT">Haïti</option>
                                                        <option value="HM">Heard en McDonald</option>
                                                        <option value="VA">Heilige Stoel</option>
                                                        <option value="HN">Honduras</option>
                                                        <option value="HU">Hongarije</option>
                                                        <option value="HK">Hongkong</option>
                                                        <option value="IE">Ierland</option>
                                                        <option value="IS">IJsland</option>
                                                        <option value="IN">India</option>
                                                        <option value="ID">Indonesië</option>
                                                        <option value="IQ">Irak</option>
                                                        <option value="IR">Iran</option>
                                                        <option value="IL">Israël</option>
                                                        <option value="IT">Italië</option>
                                                        <option value="CI">Ivoorkust</option>
                                                        <option value="JM">Jamaica</option>
                                                        <option value="JP">Japan</option>
                                                        <option value="YE">Jemen</option>
                                                        <option value="JE">Jersey</option>
                                                        <option value="JO">Jordanië</option>
                                                        <option value="CV">Kaapverdië</option>
                                                        <option value="CM">Kameroen</option>
                                                        <option value="KZ">Kazachstan</option>
                                                        <option value="KE">Kenia</option>
                                                        <option value="KG">Kirgizstan</option>
                                                        <option value="KI">Kiribati</option>
                                                        <option value="KW">Koeweit</option>
                                                        <option value="XK">Kosovo</option>
                                                        <option value="HR">Kroatië</option>
                                                        <option value="LA">Laos</option>
                                                        <option value="LS">Lesotho</option>
                                                        <option value="LV">Letland</option>
                                                        <option value="LB">Libanon</option>
                                                        <option value="LR">Liberia</option>
                                                        <option value="LY">Libië</option>
                                                        <option value="LI">Liechtenstein</option>
                                                        <option value="LT">Litouwen</option>
                                                        <option value="LU">Luxemburg</option>
                                                        <option value="MO">Macao</option>
                                                        <option value="MK">Macedonië</option>
                                                        <option value="MG">Madagaskar</option>
                                                        <option value="MW">Malawi</option>
                                                        <option value="MV">Maldiven</option>
                                                        <option value="MY">Maleisië</option>
                                                        <option value="ML">Mali</option>
                                                        <option value="MT">Malta</option>
                                                        <option value="MA">Marokko</option>
                                                        <option value="MH">Marshall</option>
                                                        <option value="MQ">Martinique</option>
                                                        <option value="MR">Mauritanië</option>
                                                        <option value="MU">Mauritius</option>
                                                        <option value="YT">Mayotte</option>
                                                        <option value="XL">Melilla</option>
                                                        <option value="MX">Mexico</option>
                                                        <option value="FM">Micronesië</option>
                                                        <option value="MD">Moldavië</option>
                                                        <option value="MC">Monaco</option>
                                                        <option value="MN">Mongolië</option>
                                                        <option value="ME">Montenegro</option>
                                                        <option value="MS">Montserrat</option>
                                                        <option value="MZ">Mozambique</option>
                                                        <option value="MM">Myanmar</option>
                                                        <option value="NA">Namibië</option>
                                                        <option value="NR">Nauru</option>
                                                        <option value="AN">Nederlandse antillen</option>
                                                        <option value="NP">Nepal</option>
                                                        <option value="SZ">Ngwane</option>
                                                        <option value="NI">Nicaragua</option>
                                                        <option value="NC">Nieuw-Caledonië</option>
                                                        <option value="NZ">Nieuw-Zeeland</option>
                                                        <option value="NE">Niger</option>
                                                        <option value="NG">Nigeria</option>
                                                        <option value="NU">Niue</option>
                                                        <option value="KP">Noord-Korea</option>
                                                        <option value="MP">Noordelijke Marianen</option>
                                                        <option value="NO">Noorwegen</option>
                                                        <option value="NF">Norfolk</option>
                                                        <option value="UA">Oekraïne</option>
                                                        <option value="UZ">Oezbekistan</option>
                                                        <option value="OM">Oman</option>
                                                        <option value="TP">Oost-Timor</option>
                                                        <option value="AT">Oostenrijk</option>
                                                        <option value="PK">Pakistan</option>
                                                        <option value="PW">Palau</option>
                                                        <option value="PS">Palestina</option>
                                                        <option value="PA">Panama</option>
                                                        <option value="PG">Papoea-Nieuw-Guinea</option>
                                                        <option value="PY">Paraguay</option>
                                                        <option value="PE">Peru</option>
                                                        <option value="PN">Pitcairn</option>
                                                        <option value="PL">Polen</option>
                                                        <option value="PT">Portugal</option>
                                                        <option value="PR">Puerto Rico</option>
                                                        <option value="QA">Qatar</option>
                                                        <option value="RE">Réunion</option>
                                                        <option value="RO">Roemenië</option>
                                                        <option value="RU">Rusland</option>
                                                        <option value="RW">Rwanda</option>
                                                        <option value="SH">Saint Helena</option>
                                                        <option value="LC">Saint Lucia</option>
                                                        <option value="VC">Saint Vincent en de Grenadines</option>
                                                        <option value="BL">Saint-Barthélemy</option>
                                                        <option value="PM">Saint-Pierre en Miquelon</option>
                                                        <option value="SB">Salomon</option>
                                                        <option value="WS">Samoa</option>
                                                        <option value="SM">San Marino</option>
                                                        <option value="ST">São Tomé en Principe</option>
                                                        <option value="SA">Saudi-Arabië</option>
                                                        <option value="SN">Senegal</option>
                                                        <option value="RS">Servië</option>
                                                        <option value="CS">Servië en Montenegro</option>
                                                        <option value="SC">Seychellen</option>
                                                        <option value="SL">Sierra Leone</option>
                                                        <option value="SG">Singapore</option>
                                                        <option value="SX">Sint Maarten</option>
                                                        <option value="KN">Sint-Kitts en Nevis</option>
                                                        <option value="MF">Sint-Maarten</option>
                                                        <option value="SI">Slovenië</option>
                                                        <option value="SK">Slowakije</option>
                                                        <option value="SD">Soedan</option>
                                                        <option value="SO">Somalië</option>
                                                        <option value="ES">Spanje</option>
                                                        <option value="SJ">Spitsbergen en Jan Mayen</option>
                                                        <option value="LK">Sri Lanka</option>
                                                        <option value="SR">Suriname</option>
                                                        <option value="SY">Syrië</option>
                                                        <option value="TJ">Tadzjikistan</option>
                                                        <option value="TW">Taiwan</option>
                                                        <option value="TZ">Tanzania</option>
                                                        <option value="TH">Thailand</option>
                                                        <option value="TL">Timor Leste</option>
                                                        <option value="TG">Togo</option>
                                                        <option value="TK">Tokelau</option>
                                                        <option value="TO">Tonga</option>
                                                        <option value="TT">Trinidad en Tobago</option>
                                                        <option value="TD">Tsjaad</option>
                                                        <option value="CZ">Tsjechië</option>
                                                        <option value="TN">Tunesië</option>
                                                        <option value="TR">Turkije</option>
                                                        <option value="TM">Turkmenistan</option>
                                                        <option value="TC">Turks- en Caicoseilanden</option>
                                                        <option value="TV">Tuvalu</option>
                                                        <option value="UG">Uganda</option>
                                                        <option value="UY">Uruguay</option>
                                                        <option value="VU">Vanuatu</option>
                                                        <option value="VE">Venezuela</option>
                                                        <option value="UM">Ver afgelegen eilandjes van de Verenigde Staten</option>
                                                        <option value="AE">Verenigde Arabische Emiraten</option>
                                                        <option value="US">Verenigde Staten van Amerika</option>
                                                        <option value="VN">Vietnam</option>
                                                        <option value="WF">Wallis en Futuna</option>
                                                        <option value="EH">Westelijke Sahara</option>
                                                        <option value="ZM">Zambia</option>
                                                        <option value="ZW">Zimbabwe</option>
                                                        <option value="ZA">Zuid-Afrika</option>
                                                        <option value="GS">Zuid-Georgia en de Zuidelijke Sandwicheilanden</option>
                                                        <option value="KR">Zuid-Korea</option>
                                                        <option value="SS">Zuid-Soedan</option>
                                                        <option value="SE">Zweden</option>
                                                        <option value="CH">Zwitserland</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form__component form__component--fieldset form__component--fieldset--3col">
                                                <div className="form__component__column">
                                                    <label className="form__label">Postcode {this.state.requiredFields['address']['zip_code'] !== undefined ? <span className="required">*</span> : ''}</label>
                                                    <div className="form__element">
                                                        <input type="text" value={this.state.form.address.zip_code} disabled={this.state.disabledFields.address.zip_code === 'disabled'} onChange={(event) => this.handleChange(event, 'zip_code', 'address')}/>
                                                    </div>
                                                </div>
                                                <div className="form__component__column">
                                                    <label className="form__label">Huisnummer {this.state.requiredFields['address']['house_number'] !== undefined ? <span className="required">*</span> : ''}</label>
                                                    <div className="form__element">
                                                        <input type="text" value={this.state.form.address.house_number} disabled={this.state.disabledFields.address.house_number === 'disabled'} onChange={(event) => this.handleChange(event, 'house_number', 'address')}/>
                                                    </div>
                                                </div>
                                                <div className="form__component__column">
                                                    <label className="form__label">Toevoeging {this.state.requiredFields['address']['house_number_extension'] !== undefined ? <span className="required">*</span> : ''}</label>
                                                    <div className="form__element">
                                                        <input type="text" value={this.state.form.address.house_number_extension} disabled={this.state.disabledFields.address.house_number_extension === 'disabled'} onChange={(event) => this.handleChange(event, 'house_number_extension', 'address')}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form__component">
                                                <label className="form__label">Straatnaam {this.state.requiredFields['address']['street'] !== undefined ? <span className="required">*</span> : ''}</label>
                                                <div className="form__element">
                                                    <input type="text" value={this.state.form.address.street} disabled={this.state.disabledFields.address.street === 'disabled'} onChange={(event) => this.handleChange(event, 'street', 'address')}/>
                                                </div>
                                            </div>
                                            <div className="form__component">
                                                <label className="form__label">Plaats {this.state.requiredFields['address']['city'] !== undefined ? <span className="required">*</span> : ''}</label>
                                                <div className="form__element">
                                                    <input type="text" value={this.state.form.address.city} disabled={this.state.disabledFields.address.city === 'disabled'} onChange={(event) => this.handleChange(event, 'city', 'address')}/>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </form>
                            }
                        </div>
                        <div className="app__bottom">
                            <div className="buttons">
                                <span className="button button--upload" style={this.props.buttonStyle} onClick={this.handleSubmit}>Opslaan</span>
                                <Loader className="form--loader" style={{display: 'none'}} type="ThreeDots" color={this.props.buttonStyle.backgroundColor !== undefined ? this.props.buttonStyle.backgroundColor : '#606060'} height={30} width={30} />
                                <span className="button button--outline" style={this.props.outlineStyle} onClick={() => this.props.history.push('/result')}>Terug naar resultaat</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    };
}

const MapStateToProps = (state) => ({
    user: state.user,
    company: state.company,
    buttonStyle: state.buttonStyle,
    outlineStyle: state.outlineStyle,
    queue: state.queue,
    sets: state.sets,
    documents: state.documents,
    registration: state.registration
});

export default connect(MapStateToProps)(withRouter(EditResult));
