import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import readFile from './File';
import {addToQueue} from "../../store/actions/Queue";
import Header from "../Header";
import {setDocuments} from "../../store/actions/Documents";
import {runRegistration} from "../../store/actions/Registration";

class Front extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            documentType: null,
            fileData: null,
            loadedAvailableDocuments: false
        };

        this.state = this.initialState;
    }

    addToQueue(item) {
        this.props.dispatch(addToQueue(item));
    };
    
    handleUpload = async (event, uploaded_document) => {
        if(event.target.files) {
            let fileData = await readFile(event.target.files[0]);

            if(fileData.length > 0) {
                let runDocument = false;

                if (uploaded_document.document_type === 'passport') {
                    runDocument = true;
                }

                await this.setSelectedDocument(uploaded_document);

                await this.addToQueue({
                    identifier: 'UPLOAD',
                    fileData: fileData,
                    side: 'front',
                    createRegistration: Object.keys(this.props.registration).length <= 0,
                    createDocument: true,
                    document: {
                        type: uploaded_document.document_type,
                        run: runDocument
                    }
                }, this.checkForRedirect(uploaded_document));
            }
        }
    };

    setSelectedDocument = async (document) => {
        const documents = this.props.documents;
        documents.selected = document;

        await this.props.dispatch(setDocuments(documents));
    };

    checkForRedirect = async (document) => {
        let documentTypeVar = null;

        if(document.document_type === 'id_card' || document.document_type === 'passport') {
            documentTypeVar = 'id_document';
        }

        const registration = this.props.registration;
        const selectedSet = this.props.sets.selected;
        const validationTest = selectedSet.tests[0];
        const businessRule = validationTest.data_fields_business_rules[0];

        let requiredDocuments = [];

        if(businessRule === 'DOCUMENT-COLUMNS-ALL-COMPLETE') {
            validationTest.data_fields_required.forEach(function(requiredObject) {
                const document = requiredObject.tags[0].replace('document_column.', '');
                requiredDocuments.push(document);
            });
        }

        if(requiredDocuments.indexOf(documentTypeVar) !== -1) {
            requiredDocuments = requiredDocuments.filter(function(documentType) {
                return documentType !== documentTypeVar;
            });
        }

        const requiredMatches = requiredDocuments.length;
        let currentMatches = 0;

        if(Object.keys(registration).length > 0) {
            const documents = registration.documents;

            if(documents.length > 0) {
                documents.forEach(function(document) {
                    const documentObject = document.document;

                    if(documentObject.type !== undefined) {
                        let type = documentObject.type;

                        if(type === 'id_card' || type === 'passport') {
                            type = 'id_document';
                        }

                        if(requiredDocuments.indexOf(type) !== -1) {
                            currentMatches++;
                        }
                    }
                });
            }
        }

        if(currentMatches < requiredMatches) {
            if(document.document_type === 'passport') {
                this.props.history.push(this.props.location.pathname);
            } else {
                this.props.history.push(this.props.location.pathname.replace('front', 'back'));
            }
        } else {
            if(document.document_type === 'passport') {
                const hasRequiredFields = this.checkRequiredFields();

                if(hasRequiredFields === true) {
                    this.props.history.push('/process/form/extraInfo');
                }
                else {
                    this.props.history.push({
                        pathname: '/loading',
                        state: {
                            'urlTarget': '/result',
                            'action': 'registrationRun'
                        }
                    });
                }
            } else {
                this.props.history.push(this.props.location.pathname.replace('front', 'back'));
            }
        }
    };

    checkRequiredFields = () => {
        const setSettings = this.props.sets.selected.set_settings;

        if(setSettings.is_initials_required !== undefined && setSettings.is_initials_required === true) {
        	return true;
        }
        
        if(setSettings.is_phone_required !== undefined && setSettings.is_phone_required === true) {
            return true;
        }

        if(setSettings.is_email_required !== undefined && setSettings.is_email_required === true) {
            return true;
        }

        if(setSettings.is_address_required !== undefined && setSettings.is_address_required === true) {
            return true;
        }

        return false;
    };

    render() {
        return(
            <div className="app">
                <Header {...this.props} />
                <div className="app__top">
                    <div className="tips">
                        <ul className="tips__list">
                            <li className="tips__list__item tips__list__item--notice">Bij het maken van de foto aandacht voor:</li>
                            <li className="tips__list__item tips__list__item--lighting">Voldoende licht</li>
                            <li className="tips__list__item tips__list__item--flash">Geen flits</li>
                            <li className="tips__list__item tips__list__item--reflection">Geen reflecties</li>
                        </ul>
                    </div>
                    <div className="buttons">
                        {
                            this.props.documents.available.length > 0 &&
                            this.props.documents.available.map((item, key) => {
                                let buttonClass = 'button--upload';

                                if(this.props.registration !== undefined && Object.keys(this.props.registration).length > 0) {
                                    if (this.props.registration.documents !== undefined && this.props.registration.documents.length > 0) {
                                        this.props.registration.documents.forEach(function (document) {
                                            if(document.document !== undefined && document.document.type === item.document_type) {
                                                buttonClass = 'button--completed';
                                            }
                                        });
                                    }
                                }

                                return(
                                    <label key={key} className={"button " + buttonClass} style={this.props.buttonStyle} htmlFor={item.document_type}>
                                        {item.display_name}
                                        <input type="file" capture="camera" accept="image/*" id={item.document_type} placeholder="Maak of kies een foto" onChange={(event) => this.handleUpload(event, item)} />
                                    </label>
                                );
                            })
                        }
                    </div>
                </div>
                <div className="app__bottom">
                    <div className="buttons">
                        <span className="button button--outline" style={this.props.outlineStyle} onClick={() => this.props.history.push('/process')}>Annuleren</span>
                    </div>
                </div>
            </div>
        );
    };
}
	                            
const MapStateToProps = (state) => ({
    user: state.user,
    company: state.company,
    buttonStyle: state.buttonStyle,
    outlineStyle: state.outlineStyle,
    queue: state.queue,
    sets: state.sets,
    documents: state.documents,
    registration: state.registration
});

export default connect(MapStateToProps)(withRouter(Front));