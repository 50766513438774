export function sendRequest(object) {
	
    //const apiUrl = 'http://service.24rental.local/Rest/App/v1';
    //const apiUrl = 'https://pwa-api.test.crimimail.com/v1';
    const apiUrl = 'https://api.24idcheck.com/pwa/v1';

    let urlExtension = '';

    if (object.urlExtension !== undefined && object.urlExtension !== '') {
        urlExtension = object.urlExtension;
    }

    const headers = () => {
        const h = new Headers();

        h.append('Accept', 'application/json');

        const session = {
            authToken: localStorage.getItem('AUTH-TOKEN')
        };

        if(session.authToken && urlExtension.includes('/login') === false) {
            h.append('X-Auth-Token', session.authToken);
        }

        return h;
    };

    if(object.method === 'GET') {
        if (object.params !== undefined && object.params !== '') {
            let paramsCount = 0;
            Object.keys(object.params).forEach(function(param) {
                if (paramsCount === 0) {
                    urlExtension += '?';
                } else if (paramsCount > 0) {
                    urlExtension += '&';
                }

                urlExtension += param + '=' + object.params[param];
                paramsCount++;
            });
        }
    }

    const request = (method, path, body) => {
      const url = `${apiUrl}${path}`;
      const options = { method, headers: headers() };

      if(body) {
          options.body = JSON.stringify(body);
      }

      return fetch(new Request(url, options));
    };

    const Api = {
        get(path) {
            return request('GET', path);
        },
        post(path, data = {}) {
            return request('POST', path, data);
        }
    };

    if(object.method === 'GET') {
        return(Api.get(urlExtension));
    }
    else if(object.method === 'POST') {
        return(Api.post(urlExtension, object.properties));
    }
}
