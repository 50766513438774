import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {getRegistration} from "../../store/actions/Registration";
import Header from "../Header";
import moment from 'moment';
import loaderGif from "../../img/assets/icons/loading_bolletjes_black.gif";


class Result extends Component {

    constructor(props) {
        super(props);

        this.initialState = {};

        this.state = this.initialState;
    }

    async componentDidMount() {
        if(this.props.registration !== undefined && Object.keys(this.props.registration).length > 0) {
            await this.props.dispatch(getRegistration(this.props.registration));
        }
    }

    getName = (personCheck) => {
        let name = '';

        if(personCheck === true) {
            if (this.props.registration.person.initials !== undefined && this.props.registration.person.initials !== '') {
                name += this.props.registration.person.initials;

                if(this.props.registration.person.initials.indexOf('.') === -1) {
                    name += '. ';
                } else {
                    name += ' ';
                }
            }

            if (this.props.registration.person.first_name !== undefined && this.props.registration.person.first_name !== '') {
                name += this.props.registration.person.first_name;
            }
        }

        return name;
    };

    getAddress = (addressCheck) => {
        let address = '';

        if(addressCheck === true) {
            if (this.props.registration.address.street !== undefined && this.props.registration.address.street !== '') {
                address += this.props.registration.address.street;
            }

            if (this.props.registration.address.house_number !== undefined && this.props.registration.address.house_number !== '') {
                address += ' ' + this.props.registration.address.house_number;
            }

            if (this.props.registration.address.house_number_extension !== undefined && this.props.registration.address.house_number_extension !== '') {
                address += this.props.registration.address.house_number_extension;
            }
        }

        return address;
    };

    showZipcodeOrCity = (addressCheck) => {
        let showZipcodeOrCity = false;

        if(addressCheck === true) {
            if ((this.props.registration.address.zip_code !== undefined && this.props.registration.address.zip_code !== '') || (this.props.registration.address.city !== undefined && this.props.registration.address.city !== '')) {
                showZipcodeOrCity = true;
            }
        }

        return showZipcodeOrCity;
    };

    showMobileOrEmail = (personCheck) => {
        let showMobileOrEmail = false;

        if(personCheck === true) {
            if ((this.props.registration.person.phone_number !== undefined && this.props.registration.person.phone_number !== '') || (this.props.registration.person.mail_address !== undefined && this.props.registration.person.mail_address !== '')) {
                showMobileOrEmail = true;
            }
        }

        return showMobileOrEmail;
    };

    checkDriversLicense = () => {
        return false;
    };

    getDocumentElements = () => {
        const documentElements = [];

        const documentsObject = {
            'drivers_license': 'Rijbewijs',
            'passport': 'Paspoort',
            'id_card': 'ID-kaart'
        };

        let rowCount = 0;

        if(this.props.registration !== undefined && Object.keys(this.props.registration).length > 0) {
            if (this.props.registration.documents.length > 0) {
                this.props.registration.documents.forEach(function (document) {
                    if (document.type !== undefined && document.number !== undefined) {
                        documentElements[rowCount] = {
                            0: documentsObject[document.type],
                            1: document.number
                        };
                        rowCount++;

                        if (document.expire_date !== undefined) {
                            documentElements[rowCount] = {
                                0: 'Geldig tot',
                                1: document.expire_date
                            };
                            rowCount++;
                        }
                    }
                });
            }
        }

        return documentElements;
    };

    render() {
        const totalResultObject = {
            'unknown': 'Onbekend',
            'ok': 'Geaccepteerd',
            'manual': 'Handmatige validatie',
            'no_pass': 'Afgekeurd',
            'expired': 'Verlopen'
        };

        const personCheck = this.props.registration.person !== undefined && Object.keys(this.props.registration.person).length > 0;
        const addressCheck = this.props.registration.address !== undefined && Object.keys(this.props.registration.address).length > 0;

        const name = this.getName(personCheck);
        const address = this.getAddress(addressCheck);
        const showZipcodeOrCity = this.showZipcodeOrCity(addressCheck);
        const showMobileOrEmail = this.showMobileOrEmail(personCheck);
        const documentElements = this.getDocumentElements();

        return(
            <div className="app">
                <Header {...this.props} />
                {
                    this.props.registration.acceptation !== undefined && Object.keys(this.props.registration.acceptation).length > 0 &&
                    <React.Fragment>
                        <div className="app__top">
                            {
                                this.props.registration.acceptation.total_result === 'unknown' &&
                                <div className="result__fetching">
                                    <div style={{"width": "100%"}} ><img src={loaderGif} alt="" style={{"width": "50px", "margin": "0 auto"}}/></div>
                                </div>
                            }
                            <div className={"result result--" + this.props.registration.acceptation.total_result}>
                                {
                                    this.props.registration.acceptation.set_name !== undefined && this.props.registration.acceptation.set_name.length > 0 &&
                                    <span style={{fontWeight: '700'}}>{this.props.registration.acceptation.set_name.toUpperCase()}</span>
                                }
                                <div className="result__inner">
                                    <span>Status</span>
                                    <span>{totalResultObject[this.props.registration.acceptation.total_result]}</span>
                                </div>
                            </div>
                            <div className="table">
                                <table className="table__data">
                                    <tbody>
                                    {
                                        name !== undefined && name !== '' &&
                                        <tr>
                                            <td colSpan="2">{name}</td>
                                        </tr>
                                    }
                                    {
                                        personCheck === true && this.props.registration.person.last_name !== undefined && this.props.registration.person.last_name !== '' &&
                                        <tr>
                                            <td colSpan="2">{this.props.registration.person.last_name}</td>
                                        </tr>
                                    }
                                    {
                                        personCheck === true && this.props.registration.person.date_of_birth !== undefined && this.props.registration.person.date_of_birth !== '' &&
                                        <tr>
                                            <td>Geboortedatum</td>
                                            <td>{moment(this.props.registration.person.date_of_birth).format('DD-MM-YYYY')}</td>
                                        </tr>
                                    }
                                    {
                                        address !== undefined && address !== '' &&
                                        <tr>
                                            <td colSpan="2">{address}</td>
                                        </tr>
                                    }
                                    {
                                        showZipcodeOrCity === true &&
                                        <tr>
                                            {
                                                this.props.registration.address.zip_code !== undefined && this.props.registration.address.zip_code !== '' &&
                                                <td>{this.props.registration.address.zip_code}</td>
                                            }
                                            {
                                                this.props.registration.address.city !== undefined && this.props.registration.address.city !== '' &&
                                                <td className="tt-u">{this.props.registration.address.city}</td>
                                            }
                                        </tr>
                                    }
                                    {
                                        showMobileOrEmail === true &&
                                        <tr>
                                            {
                                                this.props.registration.person.phone_number !== undefined && this.props.registration.person.phone_number !== '' &&
                                                <td>{this.props.registration.person.phone_number}</td>
                                            }
                                            {
                                                this.props.registration.person.mail_address !== undefined && this.props.registration.person.mail_address !== '' &&
                                                <td>{this.props.registration.person.mail_address}</td>
                                            }
                                        </tr>
                                    }
                                    {
                                        documentElements.length > 0 &&
                                        documentElements.map((row, rowKey) => {
                                            return(
                                                <tr key={rowKey}>
                                                    {
                                                        Object.values(row).map((element, key) => {
                                                            return(
                                                                <td key={key}>{element}</td>
                                                            );
                                                        })
                                                    }
                                                </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div className="edit">
                                <span className="edit__button" style={this.props.buttonStyle} onClick={() => this.props.history.push(this.props.location.pathname + '/edit')}>Aanpassen</span>
                            </div>
                        </div>
                        <div className="app__bottom">
                            <div className="buttons">
                                <span className="button" style={this.props.buttonStyle} onClick={() => this.props.history.push('/controls')}>Controle inzien</span>
                                <span className="button button--outline" style={this.props.outlineStyle} onClick={() => this.props.history.push('/process')}>Afsluiten</span>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    };
}

const MapStateToProps = (state) => ({
    user: state.user,
    company: state.company,
    buttonStyle: state.buttonStyle,
    outlineStyle: state.outlineStyle,
    queue: state.queue,
    sets: state.sets,
    documents: state.documents,
    registration: state.registration
});

export default connect(MapStateToProps)(withRouter(Result));
