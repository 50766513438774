import {sendRequest} from "../../helpers/Request";

export const checkAddress = (zip_code, house_number, country) => {
    if(zip_code.length > 0 && house_number.length > 0 && country.length > 0) {
        const callObject = {
            method: "POST",
            urlExtension: "/address-search",
            properties: {
                address_search: {
                    zip_code: zip_code,
                    house_number: house_number,
                    country: country
                }
            }
        };

        return dispatch => {
            return sendRequest(callObject).then(res => res.json())
                .then(json => {
                    dispatch(fetchSuccess(json));
                    return json;
                })
        };
    } else {
        return dispatch => {
            dispatch(removeAddress());
        }
    }
};

export const fetchSuccess = address => ({
    type: 'GET_ADDRESS',
    address
});

export const removeAddress = () => ({
    type: 'REMOVE_ADDRESS',
});