import React from 'react';
import ReactDOM from 'react-dom';
import './css/stylesheet.css';
import './js/scripts.js';
import {Provider} from 'react-redux';
import configureStore from './store/configure';
import App from './App';

const store = configureStore();
const jsx = (
    <Provider store={store}>
        <App/>
    </Provider>
);

ReactDOM.render(jsx, document.getElementById('root'));
