const initialState = {};

export default (state = initialState, action) => {
    switch(action.type) {
        case 'SET_OUTLINESTYLE':
            return {
                ...action.outlineStyle
            };
        default:
            return state;
    }
};