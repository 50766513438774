import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import readFile from "./File";
import {addToQueue} from "../../store/actions/Queue";
import Header from "../Header";
import {runRegistration} from "../../store/actions/Registration";
import {sendRequest} from "../../helpers/Request";

class Back extends Component {

    constructor(props) {
        super(props);

        this.initialState = {
            documentType: null,
            fileData: null
        };

        this.state = this.initialState;
    }

    addToQueue(item) {
        this.props.dispatch(addToQueue(item));
    };

    handleUpload = async (event) => {
        if(event.target.files) {

            let fileData = await readFile(event.target.files[0]);

            if(fileData.length > 0 && Object.keys(this.props.documents.selected).length > 0) {

                await this.addToQueue({
                    identifier: 'UPLOAD',
                    fileData: fileData,
                    side: 'back',
                    createRegistration: Object.keys(this.props.registration).length <= 0,
                    createDocument: Object.keys(this.props.documents.selected).length <= 0,
                    document: {
                        type: this.props.documents.selected.type,
                        run: true
                    }
                }, this.checkForRedirect());
            }
        }
    };

    checkForRedirect = async () => {
        const registration = this.props.registration;
        const selectedSet = this.props.sets.selected;
        const validationTest = selectedSet.tests[0];
        const businessRule = validationTest.data_fields_business_rules[0];

        let requiredDocuments = [];

        if(businessRule === 'DOCUMENT-COLUMNS-ALL-COMPLETE') {
            validationTest.data_fields_required.forEach(function(requiredObject) {
                const document = requiredObject.tags[0].replace('document_column.', '');
                requiredDocuments.push(document);
            });
        }

        const requiredMatches = requiredDocuments.length;
        let currentMatches = 0;

        if(Object.keys(registration).length > 0) {
            const documents = registration.documents;

            if(documents.length > 0) {
                documents.forEach(function(document) {
                    const documentObject = document.document;
                    let type = documentObject.type;

                    if(type === 'id_card' || type === 'passport') {
                        type = 'id_document';
                    }

                    if(requiredDocuments.indexOf(type) !== -1) {
                        currentMatches++;
                    }
                });
            }
        }

        if(currentMatches < requiredMatches) {
            this.props.history.push(this.props.location.pathname.replace('back', 'front'));
        } else {
            const hasRequiredFields = this.checkRequiredFields();

            if(hasRequiredFields === true) {
                this.props.history.push('/process/form/extraInfo');
            }
            else {
                //await this.props.dispatch(runRegistration(this.props.registration, this.props.sets.selected));
                this.props.history.push({
                    pathname: '/loading',
                    state: {
                        'urlTarget': '/result',
                        'action': 'registrationRun'
                    }
                });
            }
        }
    };

    checkRequiredFields = () => {
        const setSettings = this.props.sets.selected.set_settings;

        if(setSettings.is_initials_required !== undefined && setSettings.is_initials_required === true) {
        	return true;
        }
        
        if(setSettings.is_phone_required !== undefined && setSettings.is_phone_required === true) {
            return true;
        }

        if(setSettings.is_email_required !== undefined && setSettings.is_email_required === true) {
            return true;
        }

        if(setSettings.is_address_required !== undefined && setSettings.is_address_required === true) {
            return true;
        }

        return false;
    };

    render() {
        return(
            <div className="app">
                <Header {...this.props} />
                <div className="app__top">
                    <div className="tips">
                        <ul className="tips__list">
                            <li className="tips__list__item tips__list__item--notice">Bij het maken van de foto aandacht
                                voor:
                            </li>
                            <li className="tips__list__item tips__list__item--lighting">Voldoende licht</li>
                            <li className="tips__list__item tips__list__item--flash">Geen flits</li>
                            <li className="tips__list__item tips__list__item--reflection">Geen reflecties</li>
                        </ul>
                    </div>
                </div>
                <div className="app__bottom">
                    <div className="buttons">
                        <label className="button button--upload" style={this.props.buttonStyle} htmlFor="photoBack">
                            Maak foto achterzijde
                            <input type="file" capture="camera" accept="image/*" id="photoBack" placeholder="Maak of kies een foto" onChange={(event) => this.handleUpload(event)} />
                        </label>
                        <span className="button button--outline" style={this.props.outlineStyle} onClick={() => this.props.history.push('/process')}>Annuleren</span>
                    </div>
                </div>
            </div>
        );
    };
}

const MapStateToProps = (state) => ({
    user: state.user,
    company: state.company,
    buttonStyle: state.buttonStyle,
    outlineStyle: state.outlineStyle,
    queue: state.queue,
    sets: state.sets,
    documents: state.documents,
    registration: state.registration
});

export default connect(MapStateToProps)(withRouter(Back));
