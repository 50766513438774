import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import HeaderDefault from './Header/Default';
import HeaderCancel from './Header/Cancel';

class Header extends Component {

    render() {
        const Components = {
            HeaderDefault: HeaderDefault,
            HeaderCancel: HeaderCancel
        };

        if(this.props.header === null) {
            return true;
        }

        const HeaderComponent = Components[this.props.header];
        return <HeaderComponent title={this.props.title}/>
    };
}

export default withRouter(Header);
