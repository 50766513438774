import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {connect} from 'react-redux';
import Component from './components/Component';
import Login from './components/Login';
import Password from "./components/Password";
import Privacy from "./components/Privacy";
import Process from './components/Process';
import Front from "./components/Documents/Front";
import Back from "./components/Documents/Back";
import ExtraInfo from "./components/Form/ExtraInfo";
import Loading from "./components/Loading";
import Result from "./components/Result/Result";
import EditResult from "./components/Result/EditResult";
import ResultError from "./components/Result/ResultError";
import Controls from "./components/Controls";
import page404 from "./components/404";

class Router extends React.Component {

    constructor(props) {
        super(props);

        this.initialState = {
            hideProgress: true
        };

        this.state = this.initialState;
    }

    render() {
        const loggedIn = this.props.user.username !== '';

        let isBrowser = false;
        if(!window.matchMedia('(display-mode: standalone)').matches) {
            isBrowser = true;
        }

        return (
            <div>
                <BrowserRouter>
                    <div>
                        <Switch>
                            <Route path="/controls" component={Component(Controls, {isLoggedIn: loggedIn, title: 'Controle inzien', isBrowser: isBrowser})} />
                            <Route path="/result/error" component={Component(ResultError, {isLoggedIn: loggedIn, title: 'Foutmelding', isBrowser: isBrowser})} />
                            <Route path="/result/edit" component={Component(EditResult, {isLoggedIn: loggedIn, title: 'Gegevens aanpassen', isBrowser: isBrowser})} />
                            <Route path="/result" component={Component(Result, {isLoggedIn: loggedIn, title: 'Resultaat', isBrowser: isBrowser})} />
                            <Route path="/process/form/extraInfo" component={Component(ExtraInfo, {isLoggedIn: loggedIn, title: 'Aanvullende gegevens', isBrowser: isBrowser})} />
                            <Route path="/process/documents/back" component={Component(Back, {isLoggedIn: loggedIn, title: 'Aanvullende gegevens', isBrowser: isBrowser})} />
                            <Route path="/process/documents/front" component={Component(Front, {isLoggedIn: loggedIn, title: 'Document keuze', isBrowser: isBrowser})} />
                            <Route path="/process" component={Component(Process, {isLoggedIn: loggedIn, title: 'Proces keuze', isBrowser: isBrowser})} />
                            <Route path="/loading" component={Component(Loading, {isLoggedIn: loggedIn, title: 'Data verwerken', isBrowser: isBrowser})} />
                            <Route path="/login/:identifier" component={Component(Login, {isLoggedIn: loggedIn, isBrowser: isBrowser})} />
                            <Route path="/login" component={Component(Login, {isLoggedIn: loggedIn, isBrowser: isBrowser})} />
                            <Route path="/privacy" component={Component(Privacy, {isLoggedIn: loggedIn, 'title': 'Privacy verklaring', isBrowser: isBrowser})} />
                            <Route path="/forgot-password" component={Component(Password, {isLoggedIn: loggedIn, 'title': 'Wachtwoord vergeten?', isBrowser: isBrowser})} />
                            <Route path='/' component={page404} />
                        </Switch>
                    </div>
                </BrowserRouter>
            </div>
        );
    }
}

const MapStateToProps = (state) => ({
    user: state.user,
    company: state.company,
    buttonstyle: state.buttonstyle,
    outlinestyle: state.outlinestyle,
    queue: state.queue
});

export default connect(MapStateToProps)(Router);