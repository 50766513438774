import {sendRequest} from "../../helpers/Request";

export const addDocument = (registration, upload) => {
    if(registration.registration_id === undefined || registration.registration_id === '') {
        // throw error
        console.log('error');
    }

    const callObject = {
        method: "POST",
        urlExtension: "/registrations/" + registration.registration_id + "/document",
        properties: {
            document: upload.document
        }
    };

    return dispatch => {
        return sendRequest(callObject).then(res => res.json())
            .then(json => {
                if(json.status !== 404) {
                    dispatch(fetchAddDocument(json));
                    return json;
                }
            })
    };
};

export const createRegistration = (selectedSet) => {
    const callObject = {
        method: "POST",
        urlExtension: "/registrations",
        properties: {
            set_id: selectedSet.set_id,
            registration: {}
        }
    };

    return dispatch => {
        return sendRequest(callObject).then(res => res.json())
            .then(json => {
                if(json.status !== 404) {
                    dispatch(setRegistration(json.registration));
                    return json;
                }
            })
    };
};

export const runRegistration = (registration, set = null) => {
    if(registration.registration_id === undefined || registration.registration_id === '') {
        // throw error
        console.log('error');
    }

    let urlExtension = "/registrations/" + registration.registration_id + "/run";

    if(set !== null) {
        urlExtension += "/" + set.set_id;
    }

    const callObject = {
        method: "POST",
        urlExtension: urlExtension
    };

    return dispatch => {
        return sendRequest(callObject).then(res => res.json())
            .then(json => {
                if(json.status !== 404) {
                    dispatch(setRegistration(json.registration));
                    return json;
                }
            })
    };
};

export const updateRegistration = (registration, data) => {
    if(registration.registration_id === undefined || registration.registration_id === '') {
        // throw error
        console.log('error');
    }

    const callObject = {
        method: "POST",
        urlExtension: "/registrations/" + registration.registration_id,
        properties: {
            registration: data
        }
    };

    return dispatch => {
        return sendRequest(callObject).then(res => res.json())
            .then(json => {
                if(json.status !== 404) {
                    dispatch(setRegistration(json.registration));
                    return json;
                }
            })
    };
};

export const clearRegistration = () => ({
    type: 'CLEAR_REGISTRATION'
});

export const setRegistration = (data) => ({
    type: 'SET_REGISTRATION',
    data
});

export const getRegistration = (registration) => {
    if(registration.registration_id === undefined || registration.registration_id === '') {
        // throw error
        console.log('error');
    }

    const callObject = {
        method: "GET",
        urlExtension: "/registrations/" + registration.registration_id,
    };

    return dispatch => {
        return sendRequest(callObject).then(res => res.json())
            .then(json => {
                if(json.status !== 404) {
                    dispatch(setRegistration(json.registration));
                    return json;
                }
            })
    };
};

export const fetchAddDocument = (document) => ({
    type: 'ADD_DOCUMENT',
    document
});