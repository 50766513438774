import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import Header from "../Header";

class ResultError extends Component {

    constructor(props) {
        super(props);

        this.initialState = {};

        this.state = this.initialState;
    }

    render() {
    	function renderErrorMessage(properties)
    	{
    		let hasUnfinishedExpert = false;
    		
        	if('registration' in properties) {
        		const currentRegistration = properties.registration;
        		
        		if('documents' in currentRegistration) {        			
        			for(var i = 0, len = currentRegistration.documents.length; i < len; ++ i) {
        				let currentDocument = currentRegistration.documents[i];
        				
        				if(currentDocument.processor === 'expert'
        					&& currentDocument.processor_finished !== true) {
        					
        					hasUnfinishedExpert = true;
        					break;
        				}
        			}
        		}
        	}
        	
        	if(hasUnfinishedExpert === true) {
        		return (<div className="error-notice notice--alt">
        			<div className="notice__message">
        				Document expert vereist
        			</div>
        			<ul>
        				<li>Tijdsduur gemiddeld 1-10 minuten</li>
        				<li>Denk aan openingstijden</li>
        				<li>Bekijk resultaat in backoffice</li>
        				<li>Ga door met controleren</li>
        			</ul>
				</div>);
        	}
    		
    		return (<div className="error-notice notice--alt">
    			<div className="notice__message">
    				Er is een fout opgetreden <br/>
                    Voer een van de onderstaande acties uit
                </div>
            </div>);
    	}
    	
        return(
            <div className="app">
            <Header {...this.props} />
                <div className="app__top">&nbsp;</div>
                <div className="app__bottom">
                    {renderErrorMessage(this.props)}
                    <div className="buttons">
                        <span className="button" onClick={() => this.props.history.push('/result')}>Langer wachten</span>
                        <span className="button" onClick={() => this.props.history.push('/result/edit')}>Opnieuw controleren</span>
                        <span className="button button--outline" style={this.props.outlineStyle} onClick={() => this.props.history.push('/process')}>Annuleren</span>
                    </div>
                </div>
            </div>
        );
    };
}

const MapStateToProps = (state) => ({
    user: state.user,
    queue: state.queue,
    sets: state.sets,
    documents: state.documents,
    registration: state.registration
});

export default connect(MapStateToProps)(withRouter(ResultError));
