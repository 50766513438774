const initialState = {};

export default (state = initialState, action) => {
	
    switch(action.type) {

        case 'ADD_DOCUMENT':
            return {
                ...state,
                documents: [...state.documents, action.document]
            };

        case 'ADD_DOCUMENT_IMAGE':
            return state;
  
        case 'SET_REGISTRATION':
            return action.data;
            
        case 'CLEAR_REGISTRATION':
            return initialState;
          
        default:
            return state;
    }
};