import React, { Component } from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import Header from "./Header";
import {runRegistration} from "../store/actions/Registration";
import loaderGif from "../img/assets/icons/loading_bolletjes_black.gif";


class Result extends Component {
    resultInterval;

    constructor(props) {
        super(props);

        this.initialState = {};

        this.state = this.initialState;
    }

    async componentDidMount() {
        if(this.props.location.state.urlTarget !== undefined && this.props.location.state.action !== undefined) {
            switch(this.props.location.state.action) {
                case 'registrationRun':
                    this.pollRegistration();
                    this.resultInterval = setInterval(() => this.pollRegistration(), 2000);
                    return true;
                default:
                    return true;
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.resultInterval);
    }

    pollRegistration = async () => {
        if(this.props.registration.acceptation !== undefined && Object.keys(this.props.registration.acceptation).length > 0) {
            clearInterval(this.resultInterval);

            if(this.props.sets.selected !== undefined && Object.keys(this.props.sets.selected).length > 0) {
                await this.props.dispatch(runRegistration(this.props.registration, this.props.sets.selected));
                this.props.history.push(this.props.location.state.urlTarget);
            }
        }
    };

    render() {
        return(
            <div className="app">
                <Header {...this.props} />
                <div className="app__top">
                    <div className="buttons buttons--center">
                        <div style={{"width": "100%"}}><img src={loaderGif} alt="" style={{"width": "50px", "margin": "0 auto"}}/></div>
                    </div>
                </div>
            </div>
        );
    };
}

const MapStateToProps = (state) => ({
    user: state.user,
    company: state.company,
    buttonStyle: state.buttonStyle,
    outlineStyle: state.outlineStyle,
    queue: state.queue,
    sets: state.sets,
    documents: state.documents,
    registration: state.registration
});

export default connect(MapStateToProps)(withRouter(Result));
