const initialState = {
    street: '',
    city: '',
    country: ''
};

export default (state = initialState, action) => {

    switch(action.type) {

        case 'GET_ADDRESS':
            return {
                ...action.address
            };

        case 'REMOVE_ADDRESS':
            return initialState;

        default:
            return state;
    }
};