const initialState = {
    UPLOAD: []
};

export default (state = initialState, action) => {

    switch(action.type) {

        case 'ADD_TO_QUEUE':
            return {
                ...state,
                [action.item.identifier]: [...state[action.item.identifier], action.item]
            };

        case 'REMOVE_FROM_QUEUE':
            state[action.item.identifier].splice(action.item.queueKey, 1);
            return state;

        default:
            return state;
    }
};