const initialState = {
    username: '',
    token: ''
};

export default (state = initialState, action) => {
    switch(action.type) {
        case 'SET_USER':
            return {
                ...state,
                username: action.username
            };
        case 'REMOVE_USER':
            return {
                ...state,
                username: ''
            };

        case 'SET_TOKEN':
            return {
                ...state,
                token: action.token
            };
            
        default:
            return state;
    }
};