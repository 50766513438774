import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import User from './reducers/User';
import Company from './reducers/Company';
import Buttonstyle from './reducers/Buttonstyle';
import Outlinestyle from './reducers/Outlinestyle';
import Queue from './reducers/Queue';
import Sets from './reducers/Sets';
import Documents from './reducers/Documents';
import Registration from './reducers/Registration';
import Address from "./reducers/Address";
import thunk from "redux-thunk";

export default () => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        combineReducers({
            user: User,
            company: Company,
            buttonStyle: Buttonstyle,
            outlineStyle: Outlinestyle,
            queue: Queue,
            sets: Sets,
            documents: Documents,
            registration: Registration,
            address: Address
        }),
        composeEnhancers(
            applyMiddleware(thunk)
        )
    );

    return store;
};