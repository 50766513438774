const initialState = {};

export default (state = initialState, action) => {
    switch(action.type) {
        case 'SET_BUTTONSTYLE':
            return {
                ...action.buttonStyle
            };
        default:
            return state;
    }
};